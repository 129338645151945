import React from 'react';
import MultiSelect from "react-multi-select-component";
import AppContext from '../../AppContext';
import { formatDate, formatValidInput } from '../../helper/FormatHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import DatePicker from '../shared/DatePicker';
import NumberInput from '../shared/NumberInput';


/* 
const REGEX= {
    number : '/^[0-9]+$/'
} */
export default class AdditionalInformation extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            validations: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.totalList !== prevProps.totalList) {
            this.loadPropertyList();
        }

        if (this.props.values !== prevProps.values) {
            this.setState({
                values: this.props.values,
                validations: this.props.validations
            });
        }

        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
    }

    componentDidMount() {
        this.loadPropertyList();
    }
    loadPropertyList = ()=>{
        if (Array.isArray(this.props.totalList)) {
            //Value for Customer
            let customerAdditionalProperties = this.props.customerAdditionalProperties;

            var listValues = [];
            if (this.props.values != null) {
                listValues = this.props.values
            }

            var listValidations = [];
            if (this.props.validations != null) {
                listValidations = this.props.validations
            }

            this.props.totalList.forEach(addditionalPropertiesDefs => {
                /*{
                    "addPropertydefID": 1,
                    "label": "Label Test One",
                    "description": "Label Test 1",
                    "dataType": "Text",
                    "mandatory": true,
                    "lookUpTableID": 0,
                    "lookUpTableDescription": "",
                    "rangeValidate": false,
                    "minDate": null,
                    "maxDate": null,
                    "minNumber": null,
                    "maxNumber": null,
                    "noOfDecimals": null,
                    "pageNumber": 1
                }
                let model = {
                    name        : addditionalPropertiesDefs.label,
                    value       : '',
                }
*/
                addditionalPropertiesDefs.name = addditionalPropertiesDefs.label;
                addditionalPropertiesDefs.value = '';

                //Value for Customer
                if (customerAdditionalProperties != null) {
                    customerAdditionalProperties.forEach(element => {
                        if (element.Description === addditionalPropertiesDefs.description) {
                            if (element.DataType !== 'Numeric') {
                                addditionalPropertiesDefs.value = element.value;
                            } else {
                                addditionalPropertiesDefs.value = parseInt(element.value);
                            }
                        }
                    })
                }

                if (Array.isArray(listValues)) {
                    listValues.push(addditionalPropertiesDefs);
                }

                if (addditionalPropertiesDefs.mandatory === true) {
                    let modelV = {
                        name: addditionalPropertiesDefs.label,
                        value: true
                    }
                    if (Array.isArray(listValidations))
                        listValidations.push(modelV)
                }
            });

            this.setState({
                values: listValues,
                validations: listValidations
            }, () => {
                this.switchSteps();
            });
        }
    }
    switchSteps = () => {
        switch (this.props.step) {
            default:
            case 1:
                {
                    this.context.validated(this.validateRequiredInputs(1), 1);
                    //this.context.validatedPartial(this.validateRequiredInputs(1), 1);
                    break;
                }
            case 2:
                {
                    this.context.validated2(this.validateRequiredInputs(2), 2);
                    //this.context.validatedPartial(this.validateRequiredInputs(2), 2);
                    break;
                }
            case 3:
                {
                    this.context.validated3(this.validateRequiredInputs(3), 3);
                    //this.context.validatedPartial(this.validateRequiredInputs(3), 3);
                    break;
                }
            case 4:
                {
                    this.context.validated4(this.validateRequiredInputs(4), 4);
                    //this.context.validatedPartial(this.validateRequiredInputs(4), 4);
                    break;
                }
            case 5:
                {
                    this.context.validated5(this.validateRequiredInputs(5), 5);
                    //this.context.validatedPartial(this.validateRequiredInputs(5), 5);
                    break;
                }
            case 6:
                {
                    this.context.validated6(this.validateRequiredInputs(6), 6);
                    //this.context.validatedPartial(this.validateRequiredInputs(6), 6);
                    break;
                }
            case 7:
                {
                    this.context.validated7(this.validateRequiredInputs(7), 7);
                    //this.context.validatedPartial(this.validateRequiredInputs(7), 7);
                    break;
                }
            case 8:
                {
                    this.context.validated8(this.validateRequiredInputs(8), 8);
                    //this.context.validatedPartial(this.validateRequiredInputs(8), 8);
                    break;
                }
        }
    }
    handleUpdateValueTypeList = (item, label) => {
        var aux = this.props.values;
        var auxVal = this.props.validations;

        var auxItems = item.map((country) => country.value).join(',');

        aux.forEach(element=> {
            const name = element?.name?.toLowerCase() ?? '';
            if((name.includes('trading countries') || name.includes('destination of funds') || name.includes('origin of funds') || name.includes('currencies')) && element?.name?.includes(label)){
                element.value = auxItems;
            }
        });
        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, 1);
            this.switchSteps();
        });
    }
    handleUpdateValueTypeText = (event) => {
        var aux = this.props.values;
        var auxVal = this.props.validations;

        if (Array.isArray(aux)) {
            aux.forEach(element => {
                if (element.name === event.target.id) {
                    element.value = event.target.value.toLowerCase().includes('select') ? '' : event.target.value;
                }
            });
        }

        if (Array.isArray(auxVal)) {
            auxVal.forEach(currentVal => {
                if (currentVal.name === event.target.id) {
                    currentVal.value = true;
                }
            });
        }

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, 1);
            this.switchSteps();
        });
    }

    handleUpdateValueTypeNumber = e => {
        let value = e.target.value;

        /*if (!Number(value)) {
            return;
        }*/

        var aux = this.props.values;
        var auxVal = this.props.validations;

        aux.forEach(element => {
            if (element.name === e.target.id) {
                element.value = value;
            }
        });

        auxVal.forEach(currentVal => {
            if (currentVal.name === e.target.id) {
                currentVal.value = true;
            }
        });

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, 1);
            this.switchSteps();
        });
    }

    handleUpdateValueTypeDate = (date, dayModifiers, dayPickerInput) => {

        console.log("handleUpdateValueTypeDate: " + JSON.stringify(date));
        console.log("handleUpdateValueTypeDate: " + JSON.stringify(dayPickerInput.props.inputProps.id));

        var aux = this.props.values;
        var auxVal = this.props.validations;

        aux.forEach(element => {
            if (element.name === dayPickerInput.props.inputProps.id) {
                element.value = formatDate(date);
            }
        });

        auxVal.forEach(currentVal => {
            if (currentVal.name === dayPickerInput.props.inputProps.id) {
                currentVal.value = true;
            }
        });

        this.setState({
            values: aux,
            validations: auxVal
        }, () => {
            this.props.onUpdateValues(aux, auxVal, 1);
            this.switchSteps();
        });
    }

    validateRequiredInputs(step) {
        var listValues = this.state.values;
        //var listValidations = this.state.validations;

        const fields = [];
        const others = [];

        /*{
            "addPropertydefID":1,
            "description":"Label Test 1",
            "dataType":"Text",
            "mandatory":false,
            "lookUpTableID":0,
            "lookUpTableDescription":"",
            "rangeValidate":false,
            "minDate":null,
            "maxDate":null,
            "minNumber":null,
            "maxNumber":null,
            "noOfDecimals":null,
            "pageNumber":1,
            value:
            name:
        }*/

        listValues.forEach(element => {
            if (element.mandatory === true) {
                var type = '';

                switch (element.dataType) {
                    case 'Text':
                        type = 'string';
                        break;
                    case 'Numeric':
                        {
                            type = 'wholeNumber';
                            break;
                        }
                    default:
                        type = 'string';
                        break;
                }

                var obj = {
                    displayName: element.label,
                    validateName: element.label,
                    value: element.value,
                    type: type
                }

                fields.push(obj);

                if (element.rangeValidate === true) {
                    //primero buscar el valor del elemento en el state
                    let objToValidate = null;
                    this.state.values.forEach(elementState => {
                        if (elementState.label === element.label) {
                            objToValidate = elementState;
                        }
                    });

                    //segun el tipo va a realizar las validaciones de rango
                    switch (objToValidate.dataType) {
                        case 'Text':
                            {
                                if (objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The value of ' + objToValidate.value + ' must not be more than ' + objToValidate.maxNumber + ' characters.',
                                        validation: this.textLengthVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value)        //No se puede mandar un method con parametros
                                    });
                                }
                                break;
                            }
                        case 'Numeric':
                            {
                                if (objToValidate.value !== 0 && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + objToValidate.minNumber + ' and ' + objToValidate.maxNumber + '.',
                                        validation: this.numericVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value)           //asi que lo guardo en otra lista para luego juntarlo
                                    });
                                }
                                break;
                            }
                        case 'DateTime':
                            {
                                if (objToValidate.value !== null && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + formatDate(objToValidate.minDate) + ' and ' + formatDate(objToValidate.maxDate) + '.',
                                        validation: this.dateTimeRangeVerification(objToValidate.minDate, objToValidate.maxDate, objToValidate.value)           //asi para todos
                                    });
                                }
                                break;
                            }
                        default: break;
                    }
                }
            }
        });
        const [valid, , errors] = validate(fields);
        let listErrors = [];
        switch (step) {           
            case 5:

                others.forEach(error => {                           //Acá junto con los validations por rango
                    if (error.validation !== true) {
                        errors.push(error.displayName)
                    }
                });
                listErrors = [{ tab: "", errors: errors }]
                return { valid, listErrors, type: 'Additional' };

            default:
                others.forEach(error => {
                    if (error.validation !== true) {
                        errors.push(error.displayName)
                    }
                });

                return { valid, errors, type: 'Additional' };
        }

    }

    textLengthVerification(min, max, value) {
        const newMin = parseInt(min);
        const newMax = parseInt(max);
        const lengthString = value.length;
        return (newMin <= lengthString && lengthString <= newMax);
    }

    numericVerification(min, max, value) {
        return (min <= value && value <= max);
    }

    dateTimeRangeVerification(minDate, maxDate, valueDate) {
        //const currentDate = formatStringToDate(valueDate);
        const miniDate = new Date(minDate);
        const maxiDate = new Date(maxDate);
        const currDate = new Date(valueDate);

        return (miniDate <= currDate && currDate <= maxiDate);
    }

    validateRequiredFields() {
        let listValues = this.state.values;
        let fields = [];
        let others = [];

        listValues.forEach(element => {
            if (element.mandatory === true) {
                var type = '';

                switch (element.dataType) {
                    case 'Text':
                        type = 'string';
                        break;
                    case 'Numeric':
                        {
                            type = 'wholeNumber';
                            break;
                        }
                    default:
                        type = 'string';
                        break;
                }

                var obj = {
                    displayName: element.label,
                    validateName: element.label,
                    value: element.value,
                    type: type
                }

                fields.push(obj);

                if (element.rangeValidate === true) {
                    //primero buscar el valor del elemento en el state
                    let objToValidate = null;
                    this.state.values.forEach(elementState => {
                        if (elementState.label === element.label) {
                            objToValidate = elementState;
                        }
                    });

                    //segun el tipo va a realizar las validaciones de rango
                    switch (objToValidate.dataType) {
                        case 'Text':
                            {
                                if (objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The value of ' + objToValidate.value + ' must not be more than ' + objToValidate.maxNumber + ' characters.',
                                        validation: this.textLengthVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value),        //No se puede mandar un method con parametros
                                        name: objToValidate.label
                                    });
                                }
                                break;
                            }
                        case 'Numeric':
                            {
                                if (objToValidate.value !== 0 && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + objToValidate.minNumber + ' and ' + objToValidate.maxNumber + '.',
                                        validation: this.numericVerification(objToValidate.minNumber, objToValidate.maxNumber, objToValidate.value),           //asi que lo guardo en otra lista para luego juntarlo
                                        name: objToValidate.label
                                    });
                                }
                                break;
                            }
                        case 'DateTime':
                            {
                                if (objToValidate.value !== null && objToValidate.value !== '') {
                                    others.push({
                                        displayName: 'The ' + objToValidate.label + ' value must be between ' + formatDate(objToValidate.minDate) + ' and ' + formatDate(objToValidate.maxDate) + '.',
                                        validation: this.dateTimeRangeVerification(objToValidate.minDate, objToValidate.maxDate, objToValidate.value),
                                        name: objToValidate.label           //asi para todos
                                    });
                                }
                                break;
                            }
                            default:break;
                    }
                }
            }
        });

        const [valid, validations] = validate(fields);

        var valis = this.state.validations;
        valis.forEach(element => {
            if (validations[element.name] != null) {
                element.value = validations[element.name];
            }
        });

        if (others.length !== 0) {
            // debugger
            valis.forEach(vali => {             //{name: "Label Test 3", value: true}
                others.forEach(error => {
                    /*
                        displayName: "The Label Test 3 value must be between 0 and 1000."
                        name: "Label Test 3"
                        validation: false
                    */
                    if (vali.name === error.name && error.validation === false) {
                        vali.value = false
                    }

                });
            });
        }

        this.setState({ validations: valis });
        return valid;
    }

    render() {

        if (this.props.totalList.length === 0) {
            return (<div></div>);
        } else {
            return (
                <div className="row">
                    <div className="col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-sm-6 col-xs-12 form-group">
                                <h5><b>{getResource('SubTitle_AdditionalInformation')}</b></h5>
                            </div>
                        </div>
                        <div className="row">
                            {this.props.totalList.map(addditionalPropertiesDefs => {

                                let vals = [];
                                if (this.state.values != null) {
                                    vals = this.state.values;
                                }

                                let validations = [];
                                if (this.state.validations != null) {
                                    validations = this.state.validations;
                                }
                                let tradingValues = [];
                                let tradingValuesIndex = vals.findIndex(element => {
                                    const name = element?.name?.toLowerCase() ?? '';
                                    return ((name.includes('trading countries') || name.includes('destination of funds') || name.includes('origin of funds')) && element.name === addditionalPropertiesDefs.label);
                                });
                                if (tradingValuesIndex > -1) {
                                    if (vals[tradingValuesIndex].value != null && vals[tradingValuesIndex].value !== '') {
                                        tradingValues = vals[tradingValuesIndex].value.split(',').map(item => ({ label: item, value: item }));
                                    }
                                }
                                let currencyValues = [];
                                let currencyyValuesIndex = vals.findIndex(element => {
                                    const name = element?.name?.toLowerCase() ?? '';
                                    return ((name.includes('currencies')) && element.name === addditionalPropertiesDefs.label);
                                });
                                if (currencyyValuesIndex > -1) {
                                    if (vals[currencyyValuesIndex].value != null && vals[currencyyValuesIndex].value !== '') {
                                        currencyValues = vals[currencyyValuesIndex].value.split(',').map(item => ({ label: item, value: item }));
                                    }
                                }
                                let actualValue = '';
                                let valueIndex = vals.findIndex(element => element.name === addditionalPropertiesDefs.label);
                                if (valueIndex > -1) {
                                    actualValue = vals[valueIndex].value;
                                }

                                let actualVali = true;
                                let validationsIndex = validations.findIndex(currentVal => currentVal.name === addditionalPropertiesDefs.label);
                                if (validationsIndex > -1) {
                                    actualVali = validations[validationsIndex].value;
                                }
                                switch (addditionalPropertiesDefs.dataType) {
                                    case 'Text': {
                                        const label = addditionalPropertiesDefs.label.toLowerCase();
                                        if(label.includes('trading countries') || label.includes('destination of funds') || label.includes('origin of funds')) {
                                            return (
                                                <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                                                    {/*                                              
                                                    {tradingValues.length > 0 &&
                                                        <DropdownMultiple
                                                        name="country"
                                                        title="Select..."
                                                        titleSingular="Country"
                                                        searchable={["Search countries", "No matching country"]}
                                                        list={this.context.trades}
                                                        select={tradingValues}
                                                        onChange={this.handleUpdateValueTypeList}
                                                    />
                                                    }*/}
                                                    < MultiSelect
                                                        className='extended-multiselect'
                                                        key={addditionalPropertiesDefs.label}
                                                        options={this.context.trades}
                                                        selected={tradingValues}
                                                        value={tradingValues}
                                                        onChange={(item) => this.handleUpdateValueTypeList(item, addditionalPropertiesDefs.label)}
                                                        overrideStrings={{ "selectSomeItems": `Select ${addditionalPropertiesDefs.label}...` }}
                                                    />
                                                    <label
                                                        className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                                        
                                                    </label>
                                                    {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                                        && 
                                                        <label className="instructions">{addditionalPropertiesDefs.instructions}
                                                        </label>
                                                        }

                                                    {!actualVali &&
                                                        <label
                                                            id={addditionalPropertiesDefs.label + "-error"}
                                                            class="error"
                                                        >Field Required.
                                                    </label>
                                                    }
                                                </div>
                                            );
                                        } else if (label.includes('currencies')) {
                                            return (
                                                <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                                                    < MultiSelect
                                                        className='extended-multiselect'
                                                        key={addditionalPropertiesDefs.label}
                                                        options={this.context.currencies}
                                                        selected={currencyValues}
                                                        value={currencyValues}
                                                        onChange={(item) => this.handleUpdateValueTypeList(item, addditionalPropertiesDefs.label)}
                                                        overrideStrings={{ "selectSomeItems": `Select ${addditionalPropertiesDefs.label}...` }}
                                                    />
                                                    <label
                                                        className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                                        {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                                        && 
                                                        <label className="instructions">{addditionalPropertiesDefs.instructions}
                                                        </label>
                                                        }
                                                    </label>

                                                    {!actualVali &&
                                                        <label
                                                            id={addditionalPropertiesDefs.label + "-error"}
                                                            class="error"
                                                        >Field Required.
                                                    </label>
                                                    }
                                                </div>
                                            );
                                        } else if (addditionalPropertiesDefs.lookUpTableID && addditionalPropertiesDefs.lookUpTableDetails.length > 0) {
                                            const details = [...addditionalPropertiesDefs.lookUpTableDetails].sort((a, b) => a.DisplayOrder - b.DisplayOrder);
                                            return (
                                                <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                                                    <select
                                                        className="form-control"
                                                        id={addditionalPropertiesDefs.label}
                                                        key={addditionalPropertiesDefs.label}
                                                        name="residential_address_street"
                                                        maxLength="100"
                                                        value={actualValue}
                                                        onChange={this.handleUpdateValueTypeText}
                                                    >
                                                        {details.map((d) => (
                                                            <option value={d.Description}>{d.Description}</option>
                                                        ))}
                                                    </select>

                                                    <label
                                                        className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                                    </label>
                                                    {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                                        && 
                                                        <label className="instructions">{addditionalPropertiesDefs.instructions}
                                                        </label>
                                                        }

                                                    {!actualVali &&
                                                        <label
                                                            id={addditionalPropertiesDefs.label + "-error"}
                                                            class="error"
                                                        >Field Required.
                                                    </label>
                                                    }
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id={addditionalPropertiesDefs.label}
                                                        name="residential_address_street"
                                                        maxLength="100"
                                                        value={actualValue}
                                                        onChange={this.handleUpdateValueTypeText}
                                                    />

                                                    <label
                                                        className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                                    </label>
                                                    {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                                        && 
                                                        <label className="instructions">{addditionalPropertiesDefs.instructions}
                                                        </label>
                                                        }

                                                    {!actualVali &&
                                                        <label
                                                            id={addditionalPropertiesDefs.label + "-error"}
                                                            class="error"
                                                        >Field Required.
                                                    </label>
                                                    }
                                                </div>
                                            );
                                        }
                                    }
                                    case 'Numeric': {
                                        return (
                                            <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                                                <NumberInput
                                                    id={addditionalPropertiesDefs.label}
                                                    type="Integer"
                                                    className="form-control"
                                                    value={actualValue}
                                                    onChange={this.handleUpdateValueTypeNumber}
                                                />
                                                <label
                                                    className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                                </label>
                                                {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                                        && 
                                                        <label className="instructions">{addditionalPropertiesDefs.instructions}
                                                        </label>
                                                        }
                                                {!actualVali &&
                                                    <label
                                                        id={addditionalPropertiesDefs.label + "-error"}
                                                        class="error"
                                                    >Field Required.
                                                </label>
                                                }
                                            </div>
                                        );
                                    }
                                    case 'DateTime': {
                                        return (
                                            <div className={"col-sm-6 col-xs-12 extended-field" + formatValidInput(actualVali)}>
                                                <DatePicker
                                                    id={addditionalPropertiesDefs.label}
                                                    className="form-control extended-datepicker"
                                                    readOnly={true}
                                                    value={actualValue}
                                                    onDayChange={this.handleUpdateValueTypeDate}
                                                />

                                                <label
                                                    className="small">{addditionalPropertiesDefs.label} {addditionalPropertiesDefs.mandatory && <abbr>*</abbr>}
                                                </label>
                                                {addditionalPropertiesDefs.instructions && addditionalPropertiesDefs.instructions.length > 0
                                                        && 
                                                        <label className="instructions">{addditionalPropertiesDefs.instructions}
                                                        </label>
                                                        }

                                                {!actualVali &&
                                                    <label
                                                        id={addditionalPropertiesDefs.label + "-error"}
                                                        class="error"
                                                    >Field Required.
                                                </label>
                                                }
                                            </div>
                                        );
                                    }
                                    default: return <></>;
                                }
                            })}
                        </div>
                    </div>
                </div>
            );
        }
    }
}