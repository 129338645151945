import jwt from 'jsonwebtoken';
import { getCurrentCompanyName } from '../helper/PackageJsonHelper';
import { genericCallWithBody, genericCallWithBodySession, genericDeleteWithParameters, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

const apiURL = window.REACT_APP_API_URL;
const apiKey = window.REACT_APP_API_KEY;
const sessionID = window.REACT_APP_API_SESSION_ID;
const regFormUrl = window.REACT_APP_REGFORM_URL;

export async function searchCurrencies(currency, status) {
    if (currency !== '') {
        try {
            //let sessionID = await sessionStorage.getItem('SessionID');
            let params = {
                'currency': currency,
                'status': status
            }

            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');

            let response = await fetch(apiURL + 'Currency/SearchCurrenciesByStatusMin?' + query, {
                method: 'GET',
                headers: {
                    SessionID: sessionID,
                    'APIKey': apiKey,
                },
            });
            let responseJWT = await response.json();
            var decoded = jwt.decode(responseJWT.data);

            console.log(decoded.response.currencies);
            return decoded.response.currencies;
        } catch (error) {
            console.error(error);
        }
    }
}
export async function getAddressByPersonID(personID) {
    try {
        let request = {
            PersonID: personID,
            Page: 1,
            Items: 10
        }

        let response = await fetch(apiURL + 'Address/GetAddressByPersonID', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                SessionID: sessionID,
                'APIKey': apiKey,
            },
            body: JSON.stringify(request),
        });
        let responseJWT = await response.json();
        var decoded = jwt.decode(responseJWT.data);
        console.groupCollapsed("getAddressByPersonID");
        console.table(decoded.response);
        console.groupEnd();
        return decoded.response;
    } catch (error) {
        console.error(error);
    }
}
export async function getStatesByCountry(countryId, page, items) {
    try {
        let params = {
            'CountryId': countryId,
            'Page': page,
            'Items': items
        }

        let query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');

        let response = await fetch(apiURL + 'States/GetStatesByCountry?' + query, {
            method: 'GET',
            headers: {
                SessionID: sessionID,
                'APIKey': apiKey,
            },
        });
        let responseJWT = await response.json();
        var decoded = jwt.decode(responseJWT.data);
        // console.log(decoded);
        return decoded.response;
    } catch (error) {
        console.error(error);
    }
}

export async function getLookUpTable(name) {
    try {
        let params = {
            'LookUpTablesDescription': name,
        }
        let response = await genericGetWithParameters('LookUpTable/Get', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getListOfBanks(isBank) {
    try {
        let params = {
            'isBank': isBank
        }

        let response = await genericGetWithParameters('Bank/GetListOfBanks_min', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getBanks() {
    try {
        let params = {
            'NameLike': '',
            'Page': 0,
            'Items': 0
        }

        let response = await genericGetWithParameters('Bank/GetList', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getBranchesByBankID(bankID, page, items) {
    try {
        let params = {
            'BankID': bankID,
            'Page': page,
            'Items': items
        }
        let response = await genericGetWithParameters('Branch/GetBranchesByBankID_forGrid', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getCustomerByID(customerID) {
    try {
        let params = {
            'customerID': customerID
        }

        let response = await genericGetWithParameters('Customer/GetCustomerById', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getContactByCusID(customerID) {
    try {
        let params = {
            'customerID': customerID,
            'page': 1,
            'items': 8
        }

        let response = await genericGetWithParameters('Contact/GetContactsByCustomer', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function generatePdfFile(url, path) {
    try {
        let params = {
            'url': url,
            'path': path
        }

        let response = await genericGetWithParameters('Registration/GeneratePdfFile', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function submitRegForm(model) {
    try {
        let response = await genericCallWithBodySession('PUT', "Registration/Registration", model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function submit360Form(model) {
    try {
        let response = await genericCallWithBodySession('POST', "Registration/Registration360", model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function submit360CorpForm(model) {
    try {
        let apiName = 'Registration/Registration360Corporate';
        if (getCurrentCompanyName() === 'Xushi' || getCurrentCompanyName() === 'Lighthouse' || getCurrentCompanyName() === 'QFX'){
            apiName = 'Registration/Registration360Corporate2';
        }
        let response = await genericCallWithBodySession('POST', apiName, model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function submitOwnerForm(model) {
    try {
        let response = await genericCallWithBodySession('POST', "Registration/RegistrationContact", model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function getAttachmentsBySubEntityType(subEntityType, countryId) {
    try {
        let params = {
            'subEntityType': subEntityType,
            'language': sessionStorage.getItem('360Language'),
            'countryId': countryId
        }

        let response = await genericGetWithParameters('AttachmentsBySubEntityType/GetAttachmentsBySubEntityTypeRegForm', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateEmail(validationRequestID) {
    try {
        let params = {
            'validationRequestID': validationRequestID
        }

        let response = await genericCallWithBody('POST', 'Registration/ValidateEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function sendValidateEmail(customerID, name, email) {
    try {
        let params = {
            "CustomerID": customerID,
            "Name": name,
            "Email": email
        }

        let response = await genericCallWithBodySession('POST', 'Registration/SendValidateEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function sendOwnerEmail(customerID, name, email, contactType, country, language) {
    try {
        const params = {
            'CustomerID': customerID,
            'Name': name,
            'Email': email,
            'Url': regFormUrl + contactType + '-invitation',
            'Country': country,
            'Language': language
        };
        const response = await genericCallWithBodySession('POST', 'Registration/SendEmailContactInvitation', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

// Confirmation Email after successfully registering customer
export const sendCustomerRegistrationEmail = async (email, customerID, isFinalSubmit) => {
    try {
        const params = {
            'CustomerID': customerID,
            'Email': email,
            'IsFinalSubmit': isFinalSubmit
        };
        const response = await genericCallWithBodySession('POST', 'Registration/SendCustomerRegistrationEmail', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function deleteContact(contactID) {
    try {
        let params = {
            'contactID': contactID,
        };
        let response = await genericDeleteWithParameters('Contact/DeleteContact', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function deleteAttachment(personIdentificationID) {
    try {
        let params = {
            'PersonIdentificationID': personIdentificationID,
        };
        let response = await genericDeleteWithParameters('PersonIdentification/DeleteIdentificationAndTask', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function sendEmailMainContact(model) {
    try {
        let response = await genericCallWithBodySession('POST', 'Registration/SendEmailMainContactConfirmation', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}
export async function sendSignatureEmail(customerID, name, email) {
    try {
        let params = {
            "CustomerID": customerID,
            "Name": name,
            "Email": email,
            "Url": regFormUrl + 'signature-invitation'
        }

        let response = await genericCallWithBodySession('POST', 'Registration/SendEmailSignatureInvitation', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function validateContactInvitationId(id){
    try {
        let params = {
            'ValidationRequestID': id,
        }
        let response = await genericGetWithParametersNoEncrypted('Registration/ValidateContactInvitationId',params);
        // debugger;
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function sendAgreement(model) {
    try {
        let response = await genericCallWithBodySession('POST', 'Registration/SendAgreement', model);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getServerVersion() {
    try {
        const companyName = getCurrentCompanyName();
        const params = {
            'regForm': companyName
        };
        const response = await genericGetWithParametersNoEncrypted('Version/GetRegformVersion',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function saveOwners (model) {
    try {
        const response = await genericCallWithBodySession('POST', "Customer/SaveCustomerOwner", model);
        return response;
    } catch (error) {
        console.error(error);
    }
}