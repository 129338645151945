import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import AppContext from '../../AppContext';
import { formatDate, formatStringToDate, formatValidInput } from '../../helper/FormatHelper';
import { getCurrentCompanyName, getDefaultMobileCountry } from '../../helper/PackageJsonHelper';
import { getResource } from '../../helper/ResourcesHelper';
import { validate } from '../../helper/ValidateHelper';
import { deleteAttachment, deleteContact, getStatesByCountry } from '../../networking/NetworkingRegForm';
import AlertBox from '../shared/AlertBox';
import DatePicker from '../shared/DatePicker';
import DynamicSelect from '../shared/DynamicSelect';
import LoadingBox from '../shared/LoadingBox';
import NumberInput from '../shared/NumberInput';
import DialogOwnerInvitation from './OwnerForm/DialogOwnerInvitation';
const today = new Date();
const defaultMobileCountry = getDefaultMobileCountry();

export default class Owners extends React.Component {
    static contextType = AppContext;
    state = {
        //--Show & Hidden 
        trader01: true,
        trader02: false,
        trader03: false,
        trader04: false,
        trader05: false,

        //01
        FirstName01: '',
        ShortName01: '',
        LastName01: '',
        DateOfBirth01: '',
        ResidentialAddressStreet01: '',
        ResidentialAddressLine201: '',
        ResidentialAddressCity01: '',
        ResidentialAddressStateId01: '',
        ResidentialAddressStateName01: '',
        ResidentialAddressPostalCode01: '',
        ResidentialPhoneNumber01: '',
        EmailAddress01: '',
        Fax01: '',
        MobileNumber01: '',
        SSN01: '',
        IDNo01: '',
        IDType01: '',
        IDTypeName01: '',
        countryID01: '',
        countryName01: '',
        CitizenshipID01: '',
        CitizenshipName01: '',
        OtherState01: '',
        //Xushi
        Roles01: [],
        PEP01: true,
        EasyPayAuthorised01: false,
        SalutationID01: '',
        SalutationName01: '',
        PercentageOwnership01: '',

        //02
        FirstName02: '',
        ShortName02: '',
        LastName02: '',
        DateOfBirth02: '',
        ResidentialAddressStreet02: '',
        ResidentialAddressLine202: '',
        ResidentialAddressCity02: '',
        ResidentialAddressStateId02: '',
        ResidentialAddressStateName02: '',
        ResidentialAddressPostalCode02: '',
        ResidentialPhoneNumber02: '',
        EmailAddress02: '',
        Fax02: '',
        MobileNumber02: '',
        SSN02: '',
        IDNo02: '',
        IDType02: '',
        IDTypeName02: '',
        countryID02: '',
        countryName02: '',
        CitizenshipID02: '',
        CitizenshipName02: '',
        OtherState02: '',
        //Xushi
        Roles02: [],
        PEP02: true,
        EasyPayAuthorised02: false,
        SalutationID02: '',
        SalutationName02: '',
        PercentageOwnership02: '',

        //03
        FirstName03: '',
        ShortName03: '',
        LastName03: '',
        DateOfBirth03: '',
        ResidentialAddressStreet03: '',
        ResidentialAddressLine203: '',
        ResidentialAddressCity03: '',
        ResidentialAddressStateId03: '',
        ResidentialAddressStateName03: '',
        ResidentialAddressPostalCode03: '',
        ResidentialPhoneNumber03: '',
        EmailAddress03: '',
        Fax03: '',
        MobileNumber03: '',
        SSN03: '',
        IDNo03: '',
        IDType03: '',
        IDTypeName03: '',
        countryID03: '',
        countryName03: '',
        CitizenshipID03: '',
        CitizenshipName03: '',
        OtherState03: '',
        //Xushi
        Roles03: [],
        PEP03: true,
        EasyPayAuthorised03: false,
        SalutationID03: '',
        SalutationName03: '',
        PercentageOwnership03: '',

        //04
        FirstName04: '',
        ShortName04: '',
        LastName04: '',
        DateOfBirth04: '',
        ResidentialAddressStreet04: '',
        ResidentialAddressLine204: '',
        ResidentialAddressCity04: '',
        ResidentialAddressStateId04: '',
        ResidentialAddressStateName04: '',
        ResidentialAddressPostalCode04: '',
        ResidentialPhoneNumber04: '',
        EmailAddress04: '',
        Fax04: '',
        MobileNumber04: '',
        SSN04: '',
        IDNo04: '',
        IDType04: '',
        IDTypeName04: '',
        countryID04: '',
        countryName04: '',
        CitizenshipID04: '',
        CitizenshipName04: '',
        OtherState04: '',
        //Xushi
        Roles04: [],
        PEP04: true,
        EasyPayAuthorised04: false,
        SalutationID04: '',
        SalutationName04: '',
        PercentageOwnership04: '',

        //05
        FirstName05: '',
        ShortName05: '',
        LastName05: '',
        DateOfBirth05: '',
        ResidentialAddressStreet05: '',
        ResidentialAddressLine205: '',
        ResidentialAddressCity05: '',
        ResidentialAddressStateId05: '',
        ResidentialAddressStateName05: '',
        ResidentialAddressPostalCode05: '',
        ResidentialPhoneNumber05: '',
        EmailAddress05: '',
        Fax05: '',
        MobileNumber05: '',
        SSN05: '',
        IDNo05: '',
        IDType05: '',
        IDTypeName05: '',
        countryID05: '',
        countryName05: '',
        CitizenshipID05: '',
        CitizenshipName05: '',
        OtherState05: '',
        //Xushi
        Roles05: [],
        PEP05: true,
        EasyPayAuthorised05: false,
        SalutationID05: '',
        SalutationName05: '',
        PercentageOwnership05: '',

        OtherStateIdentification: '',
        IDExpirationDate: '',
        Citizenship: '',

        //ddwns values
        salutation: [],
        saludationID: '',
        saludationName: '',

        countryIDIdentification01: '',
        countryNameIdentification01: '',
        countryIDDrivingLicense01: '',
        countryNameDrivingLicense01: '',
        countryIDPassport01: '',
        countryNamePassport01: '',
        documentTypeID01: '',
        documentTypeIDName01: '',
        documentTypeNumber01: '',
        
        countryIDIdentification02: '',
        countryNameIdentification02: '',
        countryIDDrivingLicense02: '',
        countryNameDrivingLicense02: '',
        countryIDPassport02: '',
        countryNamePassport02: '',
        documentTypeID02: '',
        documentTypeIDName02: '',
        documentTypeNumber02: '',

        countryIDIdentification03: '',
        countryNameIdentification03: '',
        countryIDDrivingLicense03: '',
        countryNameDrivingLicense03: '',
        countryIDPassport03: '',
        countryNamePassport03: '',
        documentTypeID03: '',
        documentTypeIDName03: '',
        documentTypeNumber03: '',

        countryIDIdentification04: '',
        countryNameIdentification04: '',
        countryIDDrivingLicense04: '',
        countryNameDrivingLicense04: '',
        countryIDPassport04: '',
        countryNamePassport04: '',
        documentTypeID04: '',
        documentTypeIDName04: '',
        documentTypeNumber04: '',

        countryIDIdentification05: '',
        countryNameIdentification05: '',
        countryIDDrivingLicense05: '',
        countryNameDrivingLicense05: '',
        countryIDPassport05: '',
        countryNamePassport05: '',
        documentTypeID05: '',
        documentTypeIDName05: '',
        documentTypeNumber05: '',

        individualRoles: [],
        individualRolesID: '',
        individualRolesName: '',
        jobTitles: [],
        jobTitlesID: '',
        jobTitlesName: '',
        PercentOwnership: '',
        IssueJurisdiction: '',


        countryIDIdentification: '',
        countryNameIdentification: '',
        StateIdIdentification: '',
        StateNameIdentification: '',
        countryIDDrivingLicense: '',
        countryNameDrivingLicense: '',
        countryIDPassport: '',
        countryNamePassport: '',
        countries: [],
        documentTypeID: '',
        documentTypeIDName: '',
        documentTypeNumber: '',

        //RadioButtons
        YesState: getResource('Checkbox_Yes'),
        NoState: getResource('Checkbox_No'),
        FemaleState: getResource('Checkbox_Female'),
        MaleState: getResource('Checkbox_Male'),
        clickRadio: getResource('Checkbox_Yes'),
        clickRadioGender: 'none',

        //Validations
        validations: {
            DirectorValidation: true,
            individualRolesID: true,
            //01
            FirstName01: true,
            LastName01: true,
            jobTitlesID01: true,
            DateOfBirth01: true,
            CitizenshipID01: true,
            ResidentialAddressStreet01: true,
            ResidentialAddressCity01: true,
            ResidentialAddressStateId01: true,
            ResidentialAddressPostalCode01: true,
            countryID01: true,
            EmailAddress01: true,
            countryIDIdentification01: true,
            countryIDDrivingLicense01: true,
            countryIDPassport01: true,
            numberIdentification01: true,
            documentTypeID01: true,
            //Xushi
            Roles01: true,
            PEP01: true,
            EasyPayAuthorised01: true,
            SalutationID01: true,
            PercentageOwnership01: true,

            //02
            FirstName02: true,
            LastName02: true,
            jobTitlesID02: true,
            DateOfBirth02: true,
            CitizenshipID02: true,
            ResidentialAddressStreet02: true,
            ResidentialAddressCity02: true,
            ResidentialAddressStateId02: true,
            ResidentialAddressPostalCode02: true,
            countryID02: true,
            EmailAddress02: true,
            countryIDIdentification02: true,
            countryIDDrivingLicense02: true,
            countryIDPassport02: true,
            numberIdentification02: true,
            documentTypeID02: true,
            //Xushi
            Roles02: true,
            PEP02: true,
            EasyPayAuthorised02: true,
            SalutationID02: true,
            PercentageOwnership02: true,

            //03
            FirstName03: true,
            LastName03: true,
            jobTitlesID03: true,
            DateOfBirth03: true,
            CitizenshipID03: true,
            ResidentialAddressStreet03: true,
            ResidentialAddressCity03: true,
            ResidentialAddressStateId03: true,
            ResidentialAddressPostalCode03: true,
            countryID03: true,
            EmailAddress03: true,
            countryIDIdentification03: true,
            countryIDDrivingLicense03: true,
            countryIDPassport03: true,
            numberIdentification03: true,
            documentTypeID03: true,
            //Xushi
            Roles03: true,
            PEP03: true,
            EasyPayAuthorised03: true,
            SalutationID03: true,
            PercentageOwnership03: true,

            //04
            FirstName04: true,
            LastName04: true,
            jobTitlesID04: true,
            DateOfBirth04: true,
            CitizenshipID04: true,
            ResidentialAddressStreet04: true,
            ResidentialAddressCity04: true,
            ResidentialAddressStateId04: true,
            ResidentialAddressPostalCode04: true,
            countryID04: true,
            EmailAddress04: true,
            countryIDIdentification04: true,
            countryIDDrivingLicense04: true,
            countryIDPassport04: true,
            numberIdentification04: true,
            documentTypeID04: true,
            //Xushi
            Roles04: true,
            PEP04: true,
            EasyPayAuthorised04: true,
            SalutationID04: true,
            PercentageOwnership04: true,

            //05
            FirstName05: true,
            LastName05: true,
            jobTitlesID05: true,
            DateOfBirth05: true,
            CitizenshipID05: true,
            ResidentialAddressStreet05: true,
            ResidentialAddressCity05: true,
            ResidentialAddressStateId05: true,
            ResidentialAddressPostalCode05: true,
            countryID05: true,
            EmailAddress05: true,
            countryIDIdentification05: true,
            countryIDDrivingLicense05: true,
            countryIDPassport05: true,
            numberIdentification05: true,
            documentTypeID05: true,
            //Xushi
            Roles05: true,
            PEP05: true,
            EasyPayAuthorised05: true,
            SalutationID05: true,
            PercentageOwnership05: true,

            countryIDIdentification: true,
            StateIdIdentification: true,
            IDNo: true,
            IDType: true,
            IDExpirationDate: true,
            IssueJurisdiction: true
        },
        documents: {
            personalIdentification1File: '',
            personalIdentification1FileName: '',
            personalIdentification2File: '',
            personalIdentification2FileName: '',
            drivingLicense1File: '',
            drivingLicense1FileName: '',
            drivingLicense2File: '',
            drivingLicense2FileName: '',
            passportFile: '',
            passportFileName: '',
            identityAdditionalFiles01: [],
            identityAdditionalFiles02: [],
            identityAdditionalFiles03: [],
            identityAdditionalFiles04: [],
            identityAdditionalFiles05: [],
            drivingLicenseAdditionalFiles01: [],
            drivingLicenseAdditionalFiles02: [],
            drivingLicenseAdditionalFiles03: [],
            drivingLicenseAdditionalFiles04: [],
            drivingLicenseAdditionalFiles05: [],
            passportAdditionalFiles01: [],
            passportAdditionalFiles02: [],
            passportAdditionalFiles03: [],
            passportAdditionalFiles04: [],
            passportAdditionalFiles05: [],
        },
        numberIdentification: '',
        DriverLicenseNumber: '',
        PassportNumber: '',
        valid: true,
        TaskList: [],
        showDeleteAlert: false,
        statesByCountryIDIdentification: [],
        citiesByCountryID: [],

        statesByCountryID01: [],
        statesByCountryID02: [],
        statesByCountryID03: [],
        statesByCountryID04: [],
        statesByCountryID05: [],

        //Documents
        personalIdentification1File01: '',
        personalIdentification1FileName01: '',
        personalIdentification2File01: '',
        personalIdentification2FileName01: '',
        drivingLicense1File01: '',
        drivingLicense1FileName01: '',
        drivingLicense2File01: '',
        drivingLicense2FileName01: '',
        passportFile01: '',
        passportFileName01: '',
        numberIdentification01: '',
        DriverLicenseNumber01: '',
        PassportNumber01: '',
        valid01: true,
        TaskList01: [],
        showDeleteAlert01: false,

        personalIdentification1File02: '',
        personalIdentification1FileName02: '',
        personalIdentification2File02: '',
        personalIdentification2FileName02: '',
        drivingLicense1File02: '',
        drivingLicense1FileName02: '',
        drivingLicense2File02: '',
        drivingLicense2FileName02: '',
        passportFile02: '',
        passportFileName02: '',
        numberIdentification02: '',
        DriverLicenseNumber02: '',
        PassportNumber02: '',
        valid02: true,
        TaskList02: [],
        showDeleteAlert02: false,
        
        personalIdentification1File03: '',
        personalIdentification1FileName03: '',
        personalIdentification2File03: '',
        personalIdentification2FileName03: '',
        drivingLicense1File03: '',
        drivingLicense1FileName03: '',
        drivingLicense2File03: '',
        drivingLicense2FileName03: '',
        passportFile03: '',
        passportFileName03: '',
        numberIdentification03: '',
        DriverLicenseNumber03: '',
        PassportNumber03: '',
        valid03: true,
        TaskList03: [],
        showDeleteAlert03: false,
        
        personalIdentification1File04: '',
        personalIdentification1FileName04: '',
        personalIdentification2File04: '',
        personalIdentification2FileName04: '',
        drivingLicense1File04: '',
        drivingLicense1FileName04: '',
        drivingLicense2File04: '',
        drivingLicense2FileName04: '',
        passportFile04: '',
        passportFileName04: '',
        numberIdentification04: '',
        DriverLicenseNumber04: '',
        PassportNumber04: '',
        valid04: true,
        TaskList04: [],
        showDeleteAlert04: false,
        
        personalIdentification1File05: '',
        personalIdentification1FileName05: '',
        personalIdentification2File05: '',
        personalIdentification2FileName05: '',
        drivingLicense1File05: '',
        drivingLicense1FileName05: '',
        drivingLicense2File05: '',
        drivingLicense2FileName05: '',
        passportFile05: '',
        passportFileName05: '',
        numberIdentification05: '',
        DriverLicenseNumber05: '',
        PassportNumber05: '',
        valid05: true,
        TaskList05: [],
        showDeleteAlert05: false,

        openSendEmail: false,

        //Attachments
        showAttachmentsDialogID101: false,
        showAttachmentsDialogID201: false,
        showAttachmentsDialogDL101: false,
        showAttachmentsDialogDL201: false,
        showAttachmentsDialogPS101: false,
        rowClickID101: false,
        rowClickID201: false,
        rowClickDL101: false,
        rowClickDL201: false,
        rowClickPS101: false,
        autoFocus01: false,
        showAttachmentsDialogIDAdd01: false,
        showAttachmentsDialogDLAdd01: false,
        showAttachmentsDialogPSAdd01: false,
        rowClickIDAdd01: false,
        rowClickDLAdd01: false,
        rowClickPSAdd01: false,

        showAttachmentsDialogID102: false,
        showAttachmentsDialogID202: false,
        showAttachmentsDialogDL102: false,
        showAttachmentsDialogDL202: false,
        showAttachmentsDialogPS102: false,
        rowClickID102: false,
        rowClickID202: false,
        rowClickDL102: false,
        rowClickDL202: false,
        rowClickPS102: false,
        showAttachmentsDialogIDAdd02: false,
        showAttachmentsDialogDLAdd02: false,
        showAttachmentsDialogPSAdd02: false,
        rowClickIDAdd02: false,
        rowClickDLAdd02: false,
        rowClickPSAdd02: false,

        showAttachmentsDialogID103: false,
        showAttachmentsDialogID203: false,
        showAttachmentsDialogDL103: false,
        showAttachmentsDialogDL203: false,
        showAttachmentsDialogPS103: false,
        rowClickID103: false,
        rowClickID203: false,
        rowClickDL103: false,
        rowClickDL203: false,
        rowClickPS103: false,
        showAttachmentsDialogIDAdd03: false,
        showAttachmentsDialogDLAdd03: false,
        showAttachmentsDialogPSAdd03: false,
        rowClickIDAdd03: false,
        rowClickDLAdd03: false,
        rowClickPSAdd03: false,

        showAttachmentsDialogID104: false,
        showAttachmentsDialogID204: false,
        showAttachmentsDialogDL104: false,
        showAttachmentsDialogDL204: false,
        showAttachmentsDialogPS104: false,
        rowClickID104: false,
        rowClickID204: false,
        rowClickDL104: false,
        rowClickDL204: false,
        rowClickPS104: false,
        showAttachmentsDialogIDAdd04: false,
        showAttachmentsDialogDLAdd04: false,
        showAttachmentsDialogPSAdd04: false,
        rowClickIDAdd04: false,
        rowClickDLAdd04: false,
        rowClickPSAdd04: false,

        showAttachmentsDialogID105: false,
        showAttachmentsDialogID205: false,
        showAttachmentsDialogDL105: false,
        showAttachmentsDialogDL205: false,
        showAttachmentsDialogPS105: false,
        rowClickID105: false,
        rowClickID205: false,
        rowClickDL105: false,
        rowClickDL205: false,
        rowClickPS105: false,
        showAttachmentsDialogIDAdd05: false,
        showAttachmentsDialogDLAdd05: false,
        showAttachmentsDialogPSAdd05: false,
        rowClickIDAdd05: false,
        rowClickDLAdd05: false,
        rowClickPSAdd05: false,

        //Additional
        allAdditionalPropertiesDefs1:[],
        allAdditionalPropertiesDefs2:[],
        allAdditionalPropertiesDefs3:[],
        allAdditionalPropertiesDefs4:[],
        allAdditionalPropertiesDefs5:[],

        contactAdditionalProperties01: null,
        contactAdditionalProperties02: null,
        contactAdditionalProperties03: null,
        contactAdditionalProperties04: null,
        contactAdditionalProperties05: null,

        valuesOfAdditionalPropertiesDefs1: [],
        valuesOfAdditionalPropertiesDefs2: [],
        valuesOfAdditionalPropertiesDefs3: [],
        valuesOfAdditionalPropertiesDefs4: [],
        valuesOfAdditionalPropertiesDefs5: [],

        validationsOfAdditionalPropertiesDefs1: [],
        validationsOfAdditionalPropertiesDefs2: [],
        validationsOfAdditionalPropertiesDefs3: [],
        validationsOfAdditionalPropertiesDefs4: [],
        validationsOfAdditionalPropertiesDefs5: [],

        /*frontPI01 : '', 
        endPI01 : '', 
        frontDL01 : '', 
        endDL01 : '',
        frontPss01 : '',
        frontPI02 : '', 
        endPI02 : '', 
        frontDL02 : '', 
        endDL02 : '',
        frontPss02 : '',
        frontPI03 : '', 
        endPI03 : '', 
        frontDL03 : '', 
        endDL03 : '',
        frontPss03 : '',
        frontPI04 : '', 
        endPI04 : '', 
        frontDL04 : '', 
        endDL04 : '',
        frontPss04 : '',
        frontPI05 : '', 
        endPI05 : '', 
        frontDL05 : '', 
        endDL05 : '',
        frontPss05 : '',*/
    }
    componentDidMount() {
        this.context.validated4(this.validateRequiredInputs())
        //this.context.validatedPartial(this.validateRequiredInputs(true), 4);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.notFirstLoad !== this.props.notFirstLoad && !this.props.notFirstLoad) {
            this.validateRequiredFields();
        }
        if (prevProps.notFirstLoadPartial !== this.props.notFirstLoadPartial && !this.props.notFirstLoadPartial) {
            this.validateRequiredFields(true);
        }
        if (prevProps.clickNext !== this.props.clickNext && this.props.clickNext) {
            //document.getElementById("salutation").focus();
            this.setState({ autoFocus: true });
            this.props.NoClickNext();
        }
        if (prevProps.objGOwners !== this.props.objGOwners && this.props.objGOwners != null) {
            this.setState({ ...this.props.objGOwners }, () => {
                this.searchState(this.state.countryID01, 1);
                this.searchState(this.state.countryID02, 2);
                this.searchState(this.state.countryID03, 3);
                this.searchState(this.state.countryID04, 4);
                this.searchState(this.state.countryID05, 5);
                //this.searchState(this.state.countryIDIdentification, true)

                this.loadingDocumentType();
                // debugger;
                this.renderDocuments();

                this.context.validated4(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                this.props.Owners(this.state)
            });
        }
        if (prevProps.allAdditionalPropertiesDefs5 !== this.props.allAdditionalPropertiesDefs5 && this.props.allAdditionalPropertiesDefs5 != null) {
            var allAdditionalPropertiesDefs1 = this.props.allAdditionalPropertiesDefs5.map(addditionalPropertiesDefs => ({ ...addditionalPropertiesDefs, input: addditionalPropertiesDefs.label, label: addditionalPropertiesDefs.description + '01' }));
            var allAdditionalPropertiesDefs2 = this.props.allAdditionalPropertiesDefs5.map(addditionalPropertiesDefs => ({ ...addditionalPropertiesDefs, input: addditionalPropertiesDefs.label, label: addditionalPropertiesDefs.description + '02' }));
            var allAdditionalPropertiesDefs3 = this.props.allAdditionalPropertiesDefs5.map(addditionalPropertiesDefs => ({ ...addditionalPropertiesDefs, input: addditionalPropertiesDefs.label, label: addditionalPropertiesDefs.description + '03' }));
            var allAdditionalPropertiesDefs4 = this.props.allAdditionalPropertiesDefs5.map(addditionalPropertiesDefs => ({ ...addditionalPropertiesDefs, input: addditionalPropertiesDefs.label, label: addditionalPropertiesDefs.description + '04' }));
            var allAdditionalPropertiesDefs5 = this.props.allAdditionalPropertiesDefs5.map(addditionalPropertiesDefs => ({ ...addditionalPropertiesDefs, input: addditionalPropertiesDefs.label, label: addditionalPropertiesDefs.description + '05' }));
            this.setState({
                allAdditionalPropertiesDefs1,
                allAdditionalPropertiesDefs2,
                allAdditionalPropertiesDefs3,
                allAdditionalPropertiesDefs4,
                allAdditionalPropertiesDefs5,
            })
        }
    }

    changeAutoFocus = () => {
        this.setState({ autoFocus01: false })
    }

    searchState = (countryID, trader) => {

        if (countryID != null && countryID !== '') {
            getStatesByCountry(countryID, 0, 0).then(
                (value) => {
                    if (value.states) {
                        const states = value.states.map(c => ({ ...c, name: c["State Name"], value: c["State Id"] }));
                        this.setState({ ['statesByCountryID0' + trader]: states }, () => {
                            this.context.validated4(this.validateRequiredInputs());
                            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        });
                    } else {
                        this.setState({ ['statesByCountryID0' + trader]: [] }, () => {
                            this.context.validated4(this.validateRequiredInputs());
                            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        });
                    }
                }
            );
        }
    }

    //Document Type
    loadingDocumentType = () => {
        for (let i = 1; i <= 5; i++) {
            if (this.state['TaskList0' + i] != null && this.state['TaskList0' + i].length > 0) {
                this.state['TaskList0' + i].forEach(value => {
                    this.switchDocumentType(value, i);
                });
            }
        }
    }

    switchDocumentType = (value, director) => {
        let documentTypeID = '';
        // debugger;
        switch (value.Title) {
            case 'Personal Identification document - 1':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                break;
            case 'Personal Identification document - 2':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                break;
            case 'Driving License Document - 1':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                break;
            case 'Driving License Document - 2':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                break;
            case 'Personal Passport Document - 1':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                break;
            case 'Personal Identification document - Add. Pages':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                this.setState({ ['addPI0' + director]: value.Files, ['IdentificationID_PIAdd0' + director]: value.IdentificationID });
                break;
            case 'Driving License Document - Add. Pages':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                this.setState({ ['addDL0' + director]: value.Files, ['IdentificationID_DLAdd0' + director]: value.IdentificationID });
                break;
            case 'Personal Passport Document - Add. Pages':
                documentTypeID = value.DocumentType != null ? value.DocumentType : 'Identity Card';
                this.setState({ ['addPss0' + director]: value.Files, ['IdentificationID_PssAdd0' + director]: value.IdentificationID });
                break;
            default:
                break;
        }
        if(this.state['documentTypeID0' + director] == '' && documentTypeID != '')
        {
        this.setState({ ['documentTypeID0' + director]: documentTypeID });
        }
    }
//Loading Documents

    renderDocuments = () => {
        for (let i = 1; i <= 5; i++) {
            if (this.state['TaskList0' + i] != null && this.state['TaskList0' + i].length > 0) {
                this.switchDocuments(i);
            }
        }
    }

    switchDocuments = (director) => {
        this.state['TaskList0' + director].forEach((value) => {
            switch (value.Title) {
                case 'Personal Identification document - 1':
                    this.setState({ ['frontPI0' + director]: value.Files, ['IdentificationID_frontPI0' + director]: value.IdentificationID });
                    break;
                case 'Personal Identification document - 2':
                    this.setState({ ['endPI0' + director]: value.Files, ['IdentificationID_endPI0' + director]: value.IdentificationID });
                    break;
                case 'Driving License Document - 1':
                    this.setState({ ['frontDL0' + director]: value.Files, ['IdentificationID_frontDL0' + director]: value.IdentificationID });
                    break;
                case 'Driving License Document - 2':
                    this.setState({ ['endDL0' + director]: value.Files, ['IdentificationID_endDL0' + director]: value.IdentificationID });
                    break;
                case 'Personal Passport Document - 1':
                    this.setState({ ['frontPss0' + director]: value.Files, ['IdentificationID_frontPss0' + director]: value.IdentificationID });
                    break;
                default:
                    break;
            }
        });
    }

     handleUpdateCountryIdentification = (event, number, director, value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    switch (number) {
                        case 1:
                            this.setState({
                                ['countryIDIdentification0' + director]: value.value, 
                                ['countryNameIdentification0' + director]: value.name,
                                validations: { ...this.state.validations, countryIDIdentification: true }
                            }, () => {
                                this.context.validated4(this.validateRequiredInputs())
                                this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                                this.props.Owners(this.state);
                            });
                            break;
                        case 2:
                            this.setState({
                                countryIDDrivingLicense: value.value, countryNameDrivingLicense: value.name,
                                validations: { ...this.state.validations, countryIDDrivingLicense: true }
                            }, () => {
                                this.context.validated4(this.validateRequiredInputs())
                                this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                                this.props.Owners(this.state);
                            });
                            break;
                        case 3:
                            this.setState({
                                countryIDPassport: value.value, countryNamePassport: value.name,
                                validations: { ...this.state.validations, countryIDPassport: true }
                            }, () => {
                                this.context.validated4(this.validateRequiredInputs())
                                this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                                this.props.Owners(this.state);
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    this.setState({
                        countryIDIdentification: '', countryNameIdentification: ''
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                                this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                                this.props.Owners(this.state)
                    });
                }
            }
        }
    }
    StateIdIdentification = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        StateIdIdentification: value.value, StateNameIdentification: value.name, OtherStateIdentification: '',
                        validations: { ...this.state.validations, StateIdIdentification: true }
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                } else {
                    this.setState({
                        StateIdIdentification: '', StateNameIdentification: ''
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                }
            }
        }
    }

    handleUpdateDocumentType = (director, value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        validations: {
                            ...this.state.validations,
                            ['documentTypeID0' + director]: true,
                            ['countryIDIdentification0' + director]: true,
                            ['countryIDDrivingLicense0' + director]: true,
                            ['countryIDPassport0' + director]: true,
                            ['documentTypeNumber0' + director]: true,
                            ['DriverLicenseNumber0' + director]: true,
                            ['PassportNumber0' + director]: true,
                            ['numberIdentification0' + director]: true,
                        }
                    }, () => {
                        this.switchFunction(director, 'documentTypeID0', value.value);
                        this.switchFunction(director, 'documentTypeIDName0', value.name);
                        this.switchFunction(director, 'documentTypeNumber0', value.name);
                    })
                } else {
                    this.switchFunction(director, 'documentTypeID0', '');
                    this.switchFunction(director, 'documentTypeIDName0', '');
                    this.switchFunction(director, 'documentTypeNumber0', '');
                }
            }
        }
    }

    handleUpdateRadioButtons = (event) => {
        const question = event.target.id;
        var result = event.target.value;
        switch (question) {
            case 'politically_exposed_person_yes':
                this.setState({ YesState: result, clickRadio: getResource('Checkbox_Yes') },
                    () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                break;
            case 'politically_exposed_person_no':
                this.setState({ NoState: result, clickRadio: getResource('Checkbox_No') },
                    () => {

                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                break;
            case 'gender_female':
                this.setState({ FemaleState: result, clickRadioGender: getResource('Checkbox_Female') },
                    () => {

                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                break;
            case 'gender_male':
                this.setState({ MaleState: result, clickRadioGender: getResource('Checkbox_Male') },
                    () => {

                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                break;
            default:
                break;
        }
    }
    handleUpdateSaludation = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        saludationID: value.value, saludationName: value.name,
                        validations: { ...this.state.validations, saludationID: true }
                    }, () => {

                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                } else {
                    this.setState({
                        saludationID: '', saludationName: ''
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                }
            }
        }
    }
    handleUpdateJobTitle = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        jobTitlesID: value.value, jobTitlesName: value.name,
                        validations: { ...this.state.validations, jobTitlesID: true }
                    }, () => {

                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                } else {
                    this.setState({
                        jobTitlesID: '', jobTitlesName: ''
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                }
            }
        }
    }
    handleUpdateIndividualRoles = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        individualRolesID: value.value, individualRolesName: value.name,
                        validations: { ...this.state.validations, individualRolesID: true }
                    }, () => {

                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                } else {
                    this.setState({
                        individualRolesID: '', individualRolesName: ''
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                }
            }
        }
    }

    async handleUpdateCountry(trader, value) {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    await this.switchFunction(trader, 'countryID0', value.value);
                    await this.searchState(value.value, trader)
                    await this.switchFunction(trader, 'countryName0', value.name);
                } else {
                    await this.switchFunction(trader, 'countryID0', '');
                    await this.switchFunction(trader, 'countryName0', '');
                }
            }
        }
    }

    handleUpdateFirstName = (trader, event) => {
        this.switchFunction(trader, 'FirstName0', event.target.value);
    }

    handleUpdateShortName = (trader, event) => {
        this.switchFunction(trader, 'ShortName0', event.target.value);
    }

    handleUpdateLastName = (trader, event) => {
        this.switchFunction(trader, 'LastName0', event.target.value);
    }

    handleUpdateDateOfBirth = (trader, date) => {
        this.switchFunction(trader, 'DateOfBirth0', formatDate(date));
    }

    handleUpdateResidentialAddressStreet = (trader, event) => {
        this.switchFunction(trader, 'ResidentialAddressStreet0', event.target.value);
    }

    handlePercentageOwnership = (trader, event) => {
        this.switchFunction(trader, 'PercentageOwnership0', event.target.value);
    }

    handleUpdateSSN = (trader, event) => {
        this.switchFunction(trader, 'SSN0', event.target.value);
    }

    handleUpdateIDNo = (trader, event) => {
        this.switchFunction(trader, 'IDNo0', event.target.value);
    }

    handleUpdateIDType = (value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        IDType: value.value, IDTypeName: value.name,
                        validations: { ...this.state.validations, IDType: true }
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                } else {
                    this.setState({
                        IDType: '', IDTypeName: ''
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                }
            }
        }
    }

    handleUpdateIDExpirationDate = (trader, date) => {
        this.switchFunction(trader, 'IDExpirationDate0', date);
    }

    handleUpdateIssueJurisdiction = (trader, event) => {
        this.switchFunction(trader, 'IssueJurisdiction0', event.target.value);
    }

    handleUpdateCitizenship = (trader, value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.switchFunction(trader, 'CitizenshipID0', value.value);
                    this.switchFunction(trader, 'CitizenshipName0', value.name);
                } else {
                    this.switchFunction(trader, 'CitizenshipID0', '');
                    this.switchFunction(trader, 'CitizenshipName0', '');
                }
            }
        }

    }

    handleUpdateResidentialAddressCity = (trader, event) => {
        this.switchFunction(trader, 'ResidentialAddressCity0', event.target.value);
    }

    handleUpdateResidentialAddressState = (trader, value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.switchFunction(trader, 'ResidentialAddressStateId0', value.value);
                    this.switchFunction(trader, 'ResidentialAddressStateName0', value.name);
                    this.switchFunction(trader, 'OtherState0', '');
                } else {
                    this.switchFunction(trader, 'ResidentialAddressStateId0', '');
                    this.switchFunction(trader, 'ResidentialAddressStateName0', '');
                }
            }
        }
    }
    handleUpdateState = (trader, event) => {
        this.switchFunction(trader, 'OtherState0', event.target.value);
        this.switchFunction(trader, 'ResidentialAddressStateId0', '');
    }

    handleUpdateStateIdentification = (trader, event) => {
        this.switchFunction(trader, 'OtherStateIdentification0', event.target.value);
    }

    handleUpdateResidentialAddressPostalCode = (trader, event) => {
        this.switchFunction(trader, 'ResidentialAddressPostalCode0', event.target.value);
    }

    handleUpdateResidentialPhoneNumber = (trader, phone) => {
        this.switchFunction(trader, 'ResidentialPhoneNumber0', phone);
    }

    handleUpdateEmailAddress = (trader, event) => {
        this.switchFunction(trader, 'EmailAddress0', event.target.value);
    }

    handleUpdateFax = (trader, phone) => {
        this.switchFunction(trader, 'Fax0', phone);
    }

    handleUpdateMobileNumber = (trader, phone) => {
        this.switchFunction(trader, 'MobileNumber0', phone);
    }

    handleUpdateDriverLicenseNumber = (trader, event) => {
        this.switchFunction(trader, 'DriverLicenseNumber0', event.target.value);
    }

    handleUpdatePassportNumber = (trader, event) => {
        this.switchFunction(trader, 'PassportNumber0', event.target.value);
    }

    ageVerification = (trader) => {
        const dateOfBirth = formatStringToDate(this.state["DateOfBirth0" + trader]);
        //const today = new Date();
        let age = today.getFullYear() - dateOfBirth.getFullYear();
        const m = today.getMonth() - dateOfBirth.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
            age--;
        }
        return age >= 18;
    }

    handleChangeID1File = async (director, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            this.switchFunction(director, 'personalIdentification1File0', fileContent);
            this.switchFunction(director, 'personalIdentification1FileName0', file.name);
        } else {
            this.switchFunction(director, 'personalIdentification1File0', '');
            this.switchFunction(director, 'personalIdentification1FileName0', '');
        }
    }
    handleChangeID2File = async (director, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            this.switchFunction(director, 'personalIdentification2File0', fileContent);
            this.switchFunction(director, 'personalIdentification2FileName0', file.name);
        } else {
            this.switchFunction(director, 'personalIdentification2File0', '');
            this.switchFunction(director, 'personalIdentification2FileName0', '');
        }
    }
    handleChangeDL1File = async (director, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            this.switchFunction(director, 'drivingLicense1File0', fileContent);
            this.switchFunction(director, 'drivingLicense1FileName0', file.name);
        } else {
            this.switchFunction(director, 'personalIdentification2File0', '');
            this.switchFunction(director, 'personalIdentification2FileName0', '');
        }
    }
    handleChangeDL2File = async (director, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            this.switchFunction(director, 'drivingLicense2File0', fileContent);
            this.switchFunction(director, 'drivingLicense2FileName0', file.name);
        } else {
            this.switchFunction(director, 'drivingLicense2File0', '');
            this.switchFunction(director, 'drivingLicense2FileName0', '');
        }
    }
    handleChangePSSFile = async (director, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            this.switchFunction(director, 'passportFile0', fileContent);
            this.switchFunction(director, 'passportFileName0', file.name);
        } else {
            this.switchFunction(director, 'passportFile0', '');
            this.switchFunction(director, 'passportFileName0', '');
        }
    }

    handleIdentityAdditionalFiles = async (contactNumber, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newFile = {
                FileContent: fileContent,
                FileName: file.name
            };
            // Validate file duplicates
            if (!this.state.documents['identityAdditionalFiles0' + contactNumber].find((_file) => _file.FileName === file.name)) {
                const newDocuments = { ...this.state.documents, ['identityAdditionalFiles0' + contactNumber]: [...this.state.documents['identityAdditionalFiles0' + contactNumber], ...[newFile]] };
                this.setState({ documents: newDocuments });
                this.context.validated4(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                this.props.Owners(this.state)
            }
        } else {
            const newDocuments = { ...this.state.documents, ['identityAdditionalFiles0' + contactNumber]: [] };
            this.setState({ documents: newDocuments });
            this.context.validated4(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.props.Owners(this.state)
        }
    };

    clearIdentityAdditionalFiles = async (contactNumber) => {
        const newDocuments = { ...this.state.documents, ['identityAdditionalFiles0' + contactNumber]: [] };
        this.setState({ documents: newDocuments });
        this.context.validated4(this.validateRequiredInputs())
        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
        this.props.Owners(this.state)
    };

    handleDrivingLicenseAdditionalFiles = async (contactNumber, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newFile = {
                FileContent: fileContent,
                FileName: file.name
            };
            // Validate file duplicates
            if (!this.state.documents['drivingLicenseAdditionalFiles0' + contactNumber].find((_file) => _file.FileName === file.name)) {
                const newDocuments = { ...this.state.documents, ['drivingLicenseAdditionalFiles0' + contactNumber]: [...this.state.documents['drivingLicenseAdditionalFiles0' + contactNumber], ...[newFile]] };
                this.setState({ documents: newDocuments });
                this.context.validated4(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                this.props.Owners(this.state)
            }
        } else {
            const newDocuments = { ...this.state.documents, ['drivingLicenseAdditionalFiles0' + contactNumber]: [] };
            this.setState({ documents: newDocuments });
            this.context.validated4(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.props.Owners(this.state)
        }
    };

    clearDrivingLicenseAdditionalFiles = async (contactNumber) => {
        const newDocuments = { ...this.state.documents, ['drivingLicenseAdditionalFiles0' + contactNumber]: [] };
        this.setState({ documents: newDocuments });
        this.context.validated4(this.validateRequiredInputs())
        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
        this.props.Owners(this.state)
    };

    handlePassportAdditionalFiles = async (contactNumber, event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const base64 = await this.readFileAsBase64(file);
            const fileContent = base64.replace(/^data:.+;base64,/, '');
            const newFile = {
                FileContent: fileContent,
                FileName: file.name
            };
            // Validate file duplicates
            if (!this.state.documents['passportAdditionalFiles0' + contactNumber].find((_file) => _file.FileName === file.name)) {
                const newDocuments = { ...this.state.documents, ['passportAdditionalFiles0' + contactNumber]: [...this.state.documents['passportAdditionalFiles0' + contactNumber], ...[newFile]] };
                this.setState({ documents: newDocuments });
                this.context.validated4(this.validateRequiredInputs())
                this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                this.props.Owners(this.state)
            }
        } else {
            const newDocuments = { ...this.state.documents, ['passportAdditionalFiles0' + contactNumber]: [] };
            this.setState({ documents: newDocuments });
            this.context.validated4(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.props.Owners(this.state)
        }
    };

    clearPassportAdditionalFiles = async (contactNumber) => {
        const newDocuments = { ...this.state.documents, ['passportAdditionalFiles0' + contactNumber]: [] };
        this.setState({ documents: newDocuments });
        this.context.validated4(this.validateRequiredInputs())
        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
        this.props.Owners(this.state)
    };

    readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    }

    handleUpdateNumberIdentification = (director, event) => {
        this.switchFunction(director, 'numberIdentification0', event.target.value);
    }

    deleteAttachmentClick(director, type, obj) {
        this.setState({ title: 'Are you sure you want to delete this file?', showDeleteAlert: true, readyToDeleteAttachment: obj, director, type, isDirector: false });
    }

    closeDeleteAlert() {
        this.setState({ showDeleteAlert: false, readyToDeleteAttachment: {} });
    }

    openDeleteAlert() {
        this.setState({ showDeleteAlert: true });
    }
    
    yesDeleteAlert() {
        if (this.state.isDirector) {
            var director = this.state.trader;
            var labelText = 'director0' + director;
            if (this.state['ContactId0' + director] != null && this.state['ContactId0' + director] !== 0) {
                deleteContact(this.state['ContactId0' + director]).then(
                    (json) => {
                        this.setState({
                            [labelText]: this.state.value,
                            ['ContactId0' + director]: 0,
                            showDeleteAlert: false
                        }, () => {
                            this.ClearData(director);
                        });
                    }
                )
            } else {
                this.setState({ [labelText]: this.state.value, showDeleteAlert: false }, () => {
                    this.ClearData(director);
                });
            }
        } else {
            switch (this.state.director) {
                case 1:
                    this.switchYesDelete(this.state.type, 1);
                    break;
                case 2:
                    this.switchYesDelete(this.state.type, 2)
                    break;
                case 3:
                    this.switchYesDelete(this.state.type, 3)
                    break;
                case 4:
                    this.switchYesDelete(this.state.type, 4)
                    break;
                case 5:
                    this.switchYesDelete(this.state.type, 5)
                    break;
                default:
                    break;
            }
        }
    }
    switchYesDelete = (type, director) => {
        const frontPI = 'frontPI0' + director;
        const endPI = 'endPI0' + director;
        const frontDL = 'frontDL0' + director;
        const endDL = 'endDL0' + director;
        const frontPss = 'frontPss0' + director;
        let addPI = 'addPI0' + director;
        let addDL = 'addDL0' + director;
        let addPss = 'addPss0' + director;
        switch (type) {
            case frontPI:
                if (this.state[frontPI] != null && this.state[frontPI] !== '') {
                    if (this.state[frontPI][0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state['IdentificationID_frontPI0' + director]);
                        this.deleteE2EAttachment(this.state['IdentificationID_endPI0' + director]);
                        this.setState({ [frontPI]: '', [endPI]: '', showDeleteAlert: false, }, () => {
                            this.context.validated4(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.props.Owners(this.state)
                        });
                    }
                }
                break;
            case endPI:
                if (this.state[endPI] != null && this.state[endPI] !== '') {
                    if (this.state[endPI][0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state['IdentificationID_frontPI0' + director]);
                        this.deleteE2EAttachment(this.state['IdentificationID_endPI0' + director]);
                        this.setState({ [frontPI]: '', [endPI]: '', showDeleteAlert: false, }, () => {
                            this.context.validated4(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.props.Owners(this.state)
                        });
                    }
                }
                break;
            case frontDL:
                if (this.state[frontDL] != null && this.state[frontDL] !== '') {
                    if (this.state[frontDL][0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state['IdentificationID_frontDL0' + director]);
                        this.deleteE2EAttachment(this.state['IdentificationID_endDL0' + director]);
                        this.setState({ [frontDL]: '', [endDL]: '', showDeleteAlert: false, }, () => {
                            this.context.validated4(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.props.Owners(this.state)
                        });
                    }
                }
                break;
            case endDL:
                if (this.state[endDL] != null && this.state[endDL] !== '') {
                    if (this.state[endDL][0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state['IdentificationID_frontDL0' + director]);
                        this.deleteE2EAttachment(this.state['IdentificationID_endDL0' + director]);
                        this.setState({ [frontDL]: '', [endDL]: '', showDeleteAlert: false, }, () => {
                            this.context.validated4(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                            this.props.Owners(this.state)
                        });
                    }
                }
                break;
            case frontPss:
                if (this.state[frontPss] != null && this.state[frontPss] !== '') {
                    if (this.state[frontPss][0].AttachmentId === this.state.readyToDeleteAttachment.AttachmentId) {
                        this.deleteE2EAttachment(this.state['IdentificationID_frontPss0' + director]);
                        this.setState({ [frontPss]: '', showDeleteAlert: false, }, () => {
                            this.context.validated4(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                            this.props.Owners(this.state)
                        });
                    }
                }
                break;
            case addPI:
                if (this.state[addPI] != null && this.state[addPI] !== '') {
                    if (this.state[addPI].some((attachment) => attachment.AttachmentId === this.state.readyToDeleteAttachment.AttachmentId)) {
                        this.deleteE2EAttachment(this.state['IdentificationID_PIAdd0' + director]);
                        this.setState({ [addPI]: '', showDeleteAlert: false, }, () => {
                            this.context.validated4(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                            this.props.Owners(this.state)
                        });
                    }
                }
                break;
            case addDL:
                if (this.state[addDL] != null && this.state[addDL] !== '') {
                    if (this.state[addDL].some((attachment) => attachment.AttachmentId === this.state.readyToDeleteAttachment.AttachmentId)) {
                        this.deleteE2EAttachment(this.state['IdentificationID_DLAdd0' + director]);
                        this.setState({ [addDL]: '', showDeleteAlert: false, }, () => {
                            this.context.validated4(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                            this.props.Owners(this.state)
                        });
                    }
                }
                break;
            case addPss:
                if (this.state[addPss] != null && this.state[addPss] !== '') {
                    if (this.state[addPss].some((attachment) => attachment.AttachmentId === this.state.readyToDeleteAttachment.AttachmentId)) {
                        this.deleteE2EAttachment(this.state['IdentificationID_PssAdd0' + director]);
                        this.setState({ [addPss]: '', showDeleteAlert: false, }, () => {
                            this.context.validated4(this.validateRequiredInputs())
                            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                            this.props.Owners(this.state)
                        });
                    }
                }
                break;
            default:
                break;
        }
    }

    deleteE2EAttachment = (personIdentificationID) => {
        deleteAttachment(personIdentificationID).then((jsonResponse) => { this.setState({ jsonResponse }) });
    }

    //RowClickTable
    handleRowClickID1 = (director) => {
        this.setState({ ['showAttachmentsDialogID10' + director]: true, ['rowClickID10' + director]: true })
    }

    showAttachmentsDialogID1 = () => {
        this.setState({ showAttachmentsDialogID1: false })
    }

    changeStateID1 = (director) => {
        this.setState({ ['rowClickID10' + director]: false })
    }

    handleRowClickID2 = (director) => {
        this.setState({ ['showAttachmentsDialogID20' + director]: true, ['rowClickID20' + director]: true })
    }

    showAttachmentsDialogID2 = (director) => {
        this.setState({ ['showAttachmentsDialogID2' + director]: false })
    }
    changeStateID2 = (director) => {
        this.setState({ ['rowClickID20' + director]: false })
    }
    handleRowClickDL1 = (director) => {
        this.setState({ ['showAttachmentsDialogDL10' + director]: true, ['rowClickDL10' + director]: true })
    }    
    showAttachmentsDialogDL1 = (director) => {
        this.setState({ ['showAttachmentsDialogDL10' + director]: false })
    }
    changeStateDL1 = (director) => {
        this.setState({ ['rowClickDL10' + director]: false })
    }
   
    handleRowClickDL2 = (director) => {
        this.setState({ ['showAttachmentsDialogDL20' + director]: true, ['rowClickDL20' + director]: true })
    }
    showAttachmentsDialogDL2 = (director) => {
        this.setState({ ['showAttachmentsDialogDL20' + director]: false })
    }
    changeStateDL2 = (director) => {
        this.setState({ ['rowClickDL20' + director]: false })
    }
    handleRowClickPS1 = (director) => {
        this.setState({ ['showAttachmentsDialogPS10' + director]: true, ['rowClickPS10' + director]: true })
    }
    showAttachmentsDialogPS1 = (director) => {
        this.setState({ ['showAttachmentsDialogPS10' + director]: false })
    }
    changeStatePS1 = (director) => {
        this.setState({ ['rowClickPS10' + director]: false })
    }
    changeStateIDAdd = (contactNumber) => {
        this.setState({ ['rowClickID10' + contactNumber]: false })
    }
    handleRowClickIDAdd = (contactNumber) => {
        this.setState({ ['showAttachmentsDialogIDAdd0' + contactNumber]: true, ['rowClickIDAdd0' + contactNumber]: true })
    }
    showAttachmentsDialogIDAdd = (contactNumber) => {
        this.setState({ ['showAttachmentsDialogIDAdd0' + contactNumber]: false })
    }
    changeStateDLAdd = (contactNumber) => {
        this.setState({ ['rowClickIDAdd0' + contactNumber]: false })
    }
    handleRowClickDLAdd = (contactNumber) => {
        this.setState({ ['showAttachmentsDialogDLAdd0' + contactNumber]: true, ['rowClickDLAdd0' + contactNumber]: true })
    }
    showAttachmentsDialogDLAdd = (contactNumber) => {
        this.setState({ ['showAttachmentsDialogDLAdd0' + contactNumber]: false })
    }
    changeStatePSAdd = (contactNumber) => {
        this.setState({ ['rowClickPSAdd0' + contactNumber]: false })
    }
    handleRowClickPSAdd = (contactNumber) => {
        this.setState({ ['showAttachmentsDialogPSAdd0' + contactNumber]: true, ['rowClickPSAdd0' + contactNumber]: true })
    }
    showAttachmentsDialogPSAdd = (contactNumber) => {
        this.setState({ ['showAttachmentsDialogPSAdd0' + contactNumber]: false })
    }
    
    //Inputs Validations
    validateRequiredInputs(partial) {

        var helper = [];
        var fields = [];
        var objOpen = [
            this.state.trader01,
            this.state.trader02,
            this.state.trader03,
            this.state.trader04,
            this.state.trader05
        ];
        //const fields = this.switchValidateInputs(trader);
        var [valid, , errors] = [true, {}, {}];
        var xSum = 0;
        for (let i = 0; i < 5; i++) {
            if (objOpen[i]) {
                xSum = i + 1;
                fields = partial != null ? this.switchValidatePartialInputs(xSum) : this.switchValidateInputs(xSum);
                [valid, , errors] = validate(fields);
                helper.push({ tab: ' 0' + xSum, errors });
                this.setState({ ['valid' + xSum]: valid });
            }
        }
        if (helper.length === 0) {
            fields = [
                { displayName: 'Will there be any other person authorised to trade on your behalf with this account?', validateName: 'clickRadio', value: this.state.clickRadio, type: 'radio' }
            ];
            [valid, , errors] = validate(fields);
            helper.push({ tab: '', errors });
        }
        /*const [valid, validations, errors] = validate(helper);
        this.setState({ validations, listErrors });*/

        const listErrors = helper;
        return { valid, listErrors };
    }
    //Fields Validations
    validateRequiredFields(partial) {
        let listValidations = this.state.validations;
        var helper = [];
        var fields = [];
        var objOpen = [
            this.state.trader01,
            this.state.trader02,
            this.state.trader03,
            this.state.trader04,
            this.state.trader05
        ];
        //const fields = this.switchValidateInputs(trader);
        var [valid, validations, errors] = [true, {}, {}];
        var xSum = 0;
        for (let i = 0; i < 5; i++) {
            if (objOpen[i]) {
                xSum = i + 1;
                fields = partial != null ? this.switchValidatePartialInputs(xSum) : this.switchValidateInputs(xSum);
                [valid, validations, errors] = validate(fields);
                Object.assign(listValidations, validations)

                helper.push({ tab: ' 0' + xSum, errors });
                this.setState({ ['valid' + xSum]: valid, validations: listValidations });
            }
        }
        if (helper.length === 0) {
            fields = [
                { displayName: 'Will there be any other person authorised to trade on your behalf with this account?', validateName: 'clickRadio', value: this.state.clickRadio, type: 'radio' }
            ];
            [valid, validations,] = validate(fields);
            this.setState({ clickRadioValidation: validations.clickRadioValidation });
        }
        /*const [valid, validations, errors] = validate(helper);
        this.setState({ validations, listErrors });*/
        return valid;
    }

    AddClick = (trader, value) => {
        var labelText = 'trader0' + trader;
        this.setState({ [labelText]: value }, () => {
            this.context.validated4(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.props.Owners(this.state)
        });
    }

    validateRoles = (roles) => {
        return (roles != null && roles.length > 0 && roles.some(role => role === 'Authorizer' || role === 'Director' || role === 'UBO'));
    }

    validateAtLeastOneDirector = () => {
        return (
            this.state.Roles01.some(role => role === 'Director') ||
            this.state.Roles02.some(role => role === 'Director') ||
            this.state.Roles03.some(role => role === 'Director') ||
            this.state.Roles04.some(role => role === 'Director') ||
            this.state.Roles05.some(role => role === 'Director')
        );
    }

    validatePercentageOwnershipLimit = () => {
        const po1 = this.state.PercentageOwnership01 !== '' ? Number(this.state.PercentageOwnership01) : 0;
        const po2 = this.state.PercentageOwnership02 !== '' ? Number(this.state.PercentageOwnership02) : 0;
        const po3 = this.state.PercentageOwnership03 !== '' ? Number(this.state.PercentageOwnership03) : 0;
        const po4 = this.state.PercentageOwnership04 !== '' ? Number(this.state.PercentageOwnership04) : 0;
        const po5 = this.state.PercentageOwnership05 !== '' ? Number(this.state.PercentageOwnership05) : 0;
        return ((po1 + po2 + po3 + po4 + po5) <= 100);
    };

    switchFunction = (trader, label, value) => {
        var labelText = label + trader;
        this.setState({
            [labelText]: value,
            validations: { ...this.state.validations, [labelText]: true }
        }, () => {
            this.context.validated4(this.validateRequiredInputs())
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.props.Owners(this.state)
        });
    }

    switchValidateInputs = (trader) => {
        let fields = [];
        if (this.state['FirstName0' + trader] != null && this.state['FirstName0' + trader] !== '') {
            fields = [
                { displayName: getResource('Label_FirstName') + ' 0' + trader, validateName: 'FirstName0' + trader, value: this.state['FirstName0' + trader], type: 'string' },
                { displayName: getResource('Label_Surname') + ' 0' + trader, validateName: 'LastName0' + trader, value: this.state['LastName0' + trader], type: 'string' },
                { displayName: getResource('Label_DateOfBirth') + ' 0' + trader, validateName: 'DateOfBirth0' + trader, value: this.state['DateOfBirth0' + trader], type: 'string' },
                { displayName: getResource('Label_StreetAddress') + ' 0' + trader, validateName: 'ResidentialAddressStreet0' + trader, value: this.state['ResidentialAddressStreet0' + trader], type: 'string' },
                { displayName: getResource('Label_City') + ' 0' + trader, validateName: 'ResidentialAddressCity0' + trader, value: this.state['ResidentialAddressCity0' + trader], type: 'string' },
                { displayName: getResource('Label_Country') + ' 0' + trader, validateName: 'countryID0' + trader, value: this.state['countryID0' + trader], type: 'string' },
                //{ displayName: getResource('Label_State') + ' 0' + trader, validateName: 'ResidentialAddressStateId0' + trader, value: this.state['ResidentialAddressStateId0' + trader], type: 'number' },
                { displayName: getResource('Label_Postal') + ' 0' + trader, validateName: 'ResidentialAddressPostalCode0' + trader, value: this.state['ResidentialAddressPostalCode0' + trader], type: 'string' },
                // { displayName: getResource('Label_ResidentialPhoneNumber') + ' 0' + trader, validateName: 'ResidentialPhoneNumber0' + trader, value: this.state['ResidentialPhoneNumber0' + trader], type: 'string' },
                { displayName: getResource('Label_Email') + ' 0' + trader, validateName: 'EmailAddress0' + trader, value: this.state['EmailAddress0' + trader], type: 'string' },
                //{ displayName: getResource('Label_PoliticallyExposed') + ' 0' + trader, validateName: 'radio' + trader, value: this.state['radio' + trader], type: 'radio' },
                // { displayName: getResource('Label_DocumentType') + ' 0' + trader, validateName: 'documentTypeID0' + trader, value: this.state['documentTypeID0' + trader], type: 'string' },
                // { displayName: getResource('Label_Country') + ' Identification 0' + trader, validateName: 'countryIDIdentification0' + trader, value: this.state['countryIDIdentification0' + trader], type: 'string' },
                // { displayName: getResource('Label_Roles') + ' 0' + trader, validateName: 'Roles0' + trader, validation: this.validateRoles, validationParameter: this.state['Roles0' + trader] },
                { displayName: getResource('Label_PercentageOwnership') + ' 0' + trader, validateName: 'PercentageOwnership0' + trader, value: this.state['PercentageOwnership0' + trader], type: 'wholeNumber' },
            ]
            // if (trader === 1) {
            //     fields.push({ displayName: 'At least one contact must be a director', validateName: 'DirectorValidation', validation: this.validateAtLeastOneDirector });
            // }
            if (this.state['statesByCountryID0' + trader].length > 0) {
                fields.push({ displayName: getResource('Label_State') + ' 0' + trader, validateName: 'ResidentialAddressStateId0' + trader, value: this.state['ResidentialAddressStateId0' + trader], type: 'string' });
            } else {
                fields.push({ displayName: getResource('Label_State') + ' 0' + trader, validateName: 'ResidentialAddressStateId0' + trader, value: this.state['OtherState0' + trader], type: 'string' });
            }
            if (this.state['DateOfBirth0' + trader] !== '') {
                fields.push({ displayName: getResource('Label_DateOfBirth') + ': Must be older than 18 years', validation: this.ageVerification, validationParameter: trader });
            }
    
            if (this.state['PercentageOwnership0' + trader] !== '' && trader !== 1 ) { // Only validate if total owners > 1
                fields.push({ displayName: 'Total Ownership percentage of must add up to 100%', validateName: 'PercentageOwnership', validation: this.validatePercentageOwnershipLimit });
            }
        }

        //----------------Documents: Identity Card----------------
        // let document1 = false, document2 = false;
        // if (this.state['TaskList0' + trader] != null && this.state['TaskList0' + trader].length > 0) {
        //     this.state['TaskList0' + trader].forEach(value => {
        //         if (value.Title.includes('document - 1') || value.Title.includes('Document - 1')) {
        //             document1 = true;
        //         } else if (value.Title.includes('document - 2') || value.Title.includes('Document - 2')) {
        //             document2 = true;
        //         }
        //     });
        // }
        // if (this.state['documentTypeNumber0' + trader] !== '') {
        //     if (this.state['documentTypeNumber0' + trader].includes('Identity')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'numberIdentification0' + trader, value: this.state['numberIdentification0' + trader], type: 'wholeNumber' }
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front) 0' + trader, validateName: 'personalIdentification1File0' + trader, value: this.state['personalIdentification1File0' + trader], type: 'string' },
        //                 { displayName: 'Identity Card (End) 0' + trader, validateName: 'personalIdentification2File0' + trader, value: this.state['personalIdentification2File0' + trader], type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (End) 0' + trader, validateName: 'personalIdentification2File0' + trader, value: this.state['personalIdentification2File0' + trader], type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front) 0' + trader, validateName: 'personalIdentification1File0' + trader, value: this.state['personalIdentification1File0' + trader], type: 'string' },
        //             );
        //         }
        //     } else if (this.state['documentTypeNumber0' + trader].includes('Driving')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'DriverLicenseNumber0' + trader, value: this.state['DriverLicenseNumber0' + trader], type: 'wholeNumber' },
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front) 0' + trader, validateName: 'drivingLicense1File0' + trader, value: this.state['drivingLicense1File0' + trader], type: 'string' },
        //                 { displayName: 'Attachment Driving License (End) 0' + trader, validateName: 'drivingLicense2File0' + trader, value: this.state['drivingLicense2File0' + trader], type: 'string' },
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (End) 0' + trader, validateName: 'drivingLicense2File0' + trader, value: this.state['drivingLicense2File0' + trader], type: 'string' },
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front) 0' + trader, validateName: 'drivingLicense1File0' + trader, value: this.state['drivingLicense1File0' + trader], type: 'string' },
        //             );
        //         }
        //     } else if (this.state['documentTypeNumber0' + trader].includes('Passport')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'PassportNumber0' + trader, value: this.state['PassportNumber0' + trader], type: 'wholeNumber' },
        //         );
        //         if (!document1) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front) 0' + trader, validateName: 'passportFile0' + trader, value: this.state['passportFile0' + trader], type: 'string' },
        //             );
        //         }
        //     }
        // }

        return fields;
    }

    switchValidatePartialInputs = (trader) => {
        let fields = [];
        if (this.state['FirstName0' + trader] != null && this.state['FirstName0' + trader] !== '') {
            fields = [
                { displayName: getResource('Label_FirstName') + ' 0' + trader, validateName: 'FirstName0' + trader, value: this.state['FirstName0' + trader], type: 'string' },
                { displayName: getResource('Label_Surname') + ' 0' + trader, validateName: 'LastName0' + trader, value: this.state['LastName0' + trader], type: 'string' },
            ]
            if (this.state['DateOfBirth0' + trader] !== '') {
                fields.push({ displayName: getResource('Label_DateOfBirth') + ': Must be older than 18 years', validation: this.ageVerification, validationParameter: trader });
            }
            if (this.state['statesByCountryID0' + trader].length > 0) {
                if (
                    this.state['ResidentialAddressStreet0' + trader] !== '' ||
                    this.state['ResidentialAddressCity0' + trader] !== '' ||
                    this.state['countryID0' + trader] !== '' ||
                    this.state['ResidentialAddressStateName0' + trader] !== ''
                ) {
                    fields.push(
                        { displayName: getResource('Label_StreetAddress') + ' 0' + trader, validateName: 'ResidentialAddressStreet0' + trader, value: this.state['ResidentialAddressStreet0' + trader], type: 'string' },
                        { displayName: getResource('Label_City') + ' 0' + trader, validateName: 'ResidentialAddressCity0' + trader, value: this.state['ResidentialAddressCity0' + trader], type: 'string' },
                        { displayName: getResource('Label_Country') + ' 0' + trader, validateName: 'countryID0' + trader, value: this.state['countryID0' + trader], type: 'string' },
                        { displayName: getResource('Label_State') + ' 0' + trader, validateName: 'ResidentialAddressStateName0' + trader, value: this.state['ResidentialAddressStateName0' + trader], type: 'string' }
                    );
                }
            } else {
                if (
                    this.state['ResidentialAddressStreet0' + trader] !== '' ||
                    this.state['ResidentialAddressCity0' + trader] !== '' ||
                    this.state['countryID0' + trader] !== '' ||
                    this.state['ResidentialAddressStateName0' + trader] !== ''
                ) {
                    fields.push(
                        { displayName: getResource('Label_StreetAddress') + ' 0' + trader, validateName: 'ResidentialAddressStreet0' + trader, value: this.state['ResidentialAddressStreet0' + trader], type: 'string' },
                        { displayName: getResource('Label_City') + ' 0' + trader, validateName: 'ResidentialAddressCity0' + trader, value: this.state['ResidentialAddressCity0' + trader], type: 'string' },
                        { displayName: getResource('Label_Country') + ' 0' + trader, validateName: 'countryID0' + trader, value: this.state['countryID0' + trader], type: 'string' },
                        { displayName: getResource('Label_State') + ' 0' + trader, validateName: 'ResidentialAddressStateName0' + trader, value: this.state['OtherState0' + trader], type: 'string' }
                    );
                }
            }
        }
        
        //----------------Documents: Identity Card----------------
        // let document1 = false, document2 = false;
        // if (this.state['TaskList0' + trader] != null && this.state['TaskList0' + trader].length > 0) {
        //     this.state['TaskList0' + trader].forEach(value => {
        //         if (value.Title.includes('document - 1') || value.Title.includes('Document - 1')) {
        //             document1 = true;
        //         } else if (value.Title.includes('document - 2') || value.Title.includes('Document - 2')) {
        //             document2 = true;
        //         }
        //     });
        // }
        // if (this.state['countryIDIdentification0' + trader] !== '') {
        //     fields.push(
        //         { displayName: getResource('Label_DocumentType') + ' 0' + trader, validateName: 'documentTypeID0' + trader, value: this.state['documentTypeID0' + trader], type: 'string' },
        //     );
        // }
        // if (this.state['documentTypeNumber0' + trader] !== '') {
        //     if (this.state['documentTypeNumber0' + trader].includes('Identity')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'numberIdentification0' + trader, value: this.state['numberIdentification0' + trader], type: 'wholeNumber' }
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front) 0' + trader, validateName: 'personalIdentification1File0' + trader, value: this.state['personalIdentification1File0' + trader], type: 'string' },
        //                 { displayName: 'Identity Card (End) 0' + trader, validateName: 'personalIdentification2File0' + trader, value: this.state['personalIdentification2File0' + trader], type: 'string' }
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (End) 0' + trader, validateName: 'personalIdentification2File0' + trader, value: this.state['personalIdentification2File0' + trader], type: 'string' }
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Identity Card (Front) 0' + trader, validateName: 'personalIdentification1File0' + trader, value: this.state['personalIdentification1File0' + trader], type: 'string' },
        //             );
        //         }
        //     } else if (this.state['documentTypeNumber0' + trader].includes('Driving')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'DriverLicenseNumber0' + trader, value: this.state['DriverLicenseNumber0' + trader], type: 'wholeNumber' },
        //         );
        //         if (!document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front) 0' + trader, validateName: 'drivingLicense1File0' + trader, value: this.state['drivingLicense1File0' + trader], type: 'string' },
        //                 { displayName: 'Attachment Driving License (End) 0' + trader, validateName: 'drivingLicense2File0' + trader, value: this.state['drivingLicense2File0' + trader], type: 'string' },
        //             );
        //         } else if (document1 && !document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (End) 0' + trader, validateName: 'drivingLicense2File0' + trader, value: this.state['drivingLicense2File0' + trader], type: 'string' },
        //             );
        //         } else if (!document1 && document2) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front) 0' + trader, validateName: 'drivingLicense1File0' + trader, value: this.state['drivingLicense1File0' + trader], type: 'string' },
        //             );
        //         }
        //     } else if (this.state['documentTypeNumber0' + trader].includes('Passport')) {
        //         fields.push(
        //             { displayName: getResource('Label_NumberIdentification'), validateName: 'PassportNumber0' + trader, value: this.state['PassportNumber0' + trader], type: 'wholeNumber' },
        //         );
        //         if (!document1) {
        //             fields.push(
        //                 { displayName: 'Attachment Driving License (Front) 0' + trader, validateName: 'passportFile0' + trader, value: this.state['passportFile0' + trader], type: 'string' },
        //             );
        //         }
        //     }
        // }

        return fields;
    }

    RemoveClick = (trader, value) => {
        this.setState({
            title: 'Are you sure you want to delete this Contact?',
            showDeleteAlert: true,
            is: true,
            trader,
            value,
            isDirector: true
        });
    }

    ClearData = (trader) => {
        this.setState({
            ['trader0' + trader]: false,
            ['FirstName0' + trader]: '',
            ['LastName0' + trader]: '',
            ['DateOfBirth0' + trader]: '',
            ['ResidentialAddressStreet0' + trader]: '',
            ['ResidentialAddressLine20' + trader]: '',
            ['ResidentialAddressCity0' + trader]: '',
            ['countryID0' + trader]: '',
            ['countryName0' + trader]: '',
            ['CitizenshipID0' + trader]: '',
            ['ResidentialAddressStateId0' + trader]: '',
            ['ResidentialAddressStateName0' + trader]: '',
            ['ResidentialAddressPostalCode0' + trader]: '',
            ['ResidentialPhoneNumber0' + trader]: '',
            ['MobileNumber0' + trader]: '',
            ['EmailAddress0' + trader]: '',

            ['countryIDIdentification0' + trader]: '',
            ['countryNameIdentification0' + trader]: '',
            ['documentTypeIDName0' + trader]: 'Identity Card',
            ['documentTypeID0' + trader]: 'Identity Card',
            ['documentTypeNumber0' + trader]: 'Identity Card',
            ['numberIdentification0' + trader]: '',
            ['DriverLicenseNumber0' + trader]: '',
            ['PassportNumber0' + trader]: '',

            ['personalIdentification1File0' + trader]: '',
            ['personalIdentification1FileName0' + trader]: '',
            ['personalIdentification2File0' + trader]: '',
            ['personalIdentification2FileName0' + trader]: '',
            ['drivingLicense1File0' + trader]: '',
            ['drivingLicense1FileName0' + trader]: '',
            ['drivingLicense2File0' + trader]: '',
            ['drivingLicense2FileName0' + trader]: '',
            ['passportFile0' + trader]: '',
            ['passportFileName0' + trader]: '',
            ['numberIdentification0' + trader]: '',
            ['DriverLicenseNumber0' + trader]: '',
            ['PassportNumber0' + trader]: '',
            ['valid0' + trader]: true,
            ['TaskList0' + trader]: [],
            ['showDeleteAlert0' + trader]: false,
            ['statesByCountryID0' + trader]: [],
            ['showAttachmentsDialogID10' + trader]: false,
            ['showAttachmentsDialogID20' + trader]: false,
            ['showAttachmentsDialogDL10' + trader]: false,
            ['showAttachmentsDialogDL20' + trader]: false,
            ['showAttachmentsDialogPS10' + trader]: false,
            ['rowClickID10' + trader]: false,
            ['rowClickID20' + trader]: false,
            ['rowClickDL10' + trader]: false,
            ['rowClickDL20' + trader]: false,
            ['rowClickPS10' + trader]: false,

            validations: {
                ...this.state.validations,
                ['FirstName0' + trader]: true,
                ['LastName0' + trader]: true,
                ['DateOfBirth0' + trader]: true,
                ['ResidentialAddressStreet0' + trader]: true,
                ['ResidentialAddressCity0' + trader]: true,
                ['ResidentialAddressCountry0' + trader]: true,
                ['ResidentialAddressStateId0' + trader]: true,
                ['ResidentialAddressStateName0' + trader]: true,
                ['ResidentialAddressPostalCode0' + trader]: true,
                ['ResidentialPhoneNumber0' + trader]: true,
                ['EmailAddress0' + trader]: true,
                ['countryID0' + trader]: true,
                ['CitizenshipID0' + trader]: true,

                ['countryIDIdentification0' + trader]: true,
                ['countryIDDrivingLicense0' + trader]: true,
                ['countryIDPassport0' + trader]: true,
                ['numberIdentification0' + trader]: true,
                ['documentTypeID0' + trader]: true,
            }
        }, () => {
            this.context.validatedPartial(this.validateRequiredInputs(true), 4);
            this.context.validated4(this.validateRequiredInputs());
            this.props.Owners(this.state);
        })
    }

    //-------SEND EMAIL------------
    SendOwnerEmail = () => {
        this.setState({ openSendEmail: true })
    }
    CloseSendOwnerEmail = () => {
        this.setState({ openSendEmail: false })
    }

    updateValueListAdditionalProppertiesDefs = (listValues, listaValidations, contact) => {
        let labelValues = 'valuesOfAdditionalPropertiesDefs' + contact;
        let labelValidation = 'validationsOfAdditionalPropertiesDefs' + contact;
        this.setState({
            [labelValues]: listValues,
            [labelValidation]: listaValidations
        })
    }

    handleUpdateRolesCheckboxes = (contactNumber, roleName, checked) => {
        let _roles = [...this.state['Roles0' + contactNumber]];
        if (checked) {
            _roles.push(roleName);
        } else {
            _roles = _roles.filter(role => role !== roleName);
        }
        if (!_roles.includes('Director')) {
            this.switchFunction(contactNumber, 'EasyPayAuthorised0', false);
        }
        this.switchFunction(contactNumber, 'Roles0', _roles);
    };

    handleUpdatePEP = (contactNumber, event) => {
        this.switchFunction(contactNumber, 'PEP0', !event.target.checked);
    };

    handleUpdateEasyPayAuthorised = (contactNumber, event) => {
        this.switchFunction(contactNumber, 'EasyPayAuthorised0', event.target.checked);
    };

    handleUpdateSalutation = (contactNumber, value) => {
        if (value != null && value !== 'select') {
            if (value.value != null && value.value !== "select") {
                if (value.value !== "") {
                    this.setState({
                        ['SalutationID0' + contactNumber]: value.value, ['SalutationName0' + contactNumber]: value.name,
                        validations: { ...this.state.validations, ['SalutationID0' + contactNumber]: true }
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                } else {
                    this.setState({
                        ['SalutationID0' + contactNumber]: '', ['SalutationName0' + contactNumber]: ''
                    }, () => {
                        this.context.validated4(this.validateRequiredInputs())
                        this.context.validatedPartial(this.validateRequiredInputs(true), 4);
                        this.props.Owners(this.state)
                    });
                }
            }
        }
    }


    render() {
        let countries = [], citizenship = [];
        countries = this.context != null ? (this.context.countries != null ? this.context.countries : []) : [];
        citizenship = this.context != null ? (this.context.citizenship != null ? this.context.citizenship : []) : [];

        let frontPI01 = '', endPI01 = '', frontDL01 = '', endDL01 = '', frontPss01 = '', frontPI02 = '', endPI02 = '', frontDL02 = '', endDL02 = '', frontPss02 = '', frontPI03 = '', endPI03 = '', frontDL03 = '', endDL03 = '', frontPss03 = '', frontPI04 = '', endPI04 = '', frontDL04 = '', endDL04 = '', frontPss04 = '', frontPI05 = '', endPI05 = '', frontDL05 = '', endDL05 = '', frontPss05 = '';
        let addPI01, addPI02, addPI03, addPI04, addPI05 = '';
        let addDL01, addDL02, addDL03, addDL04, addDL05 = '';
        let addPss01, addPss02, addPss03, addPss04, addPss05 = '';
        for (let i = 1; i <= 5; i++) {
            switch (i) {
                case 1:
                    frontPI01 = this.state.frontPI01 != null ? this.state.frontPI01 : '';
                    endPI01 = this.state.endPI01 != null ? this.state.endPI01 : '';
                    frontDL01 = this.state.frontDL01 != null ? this.state.frontDL01 : '';
                    endDL01 = this.state.endDL01 != null ? this.state.endDL01 : '';
                    frontPss01 = this.state.frontPss01 != null ? this.state.frontPss01 : '';
                    addPI01 = this.state.addPI01 != null ? this.state.addPI01 : '';
                    addDL01 = this.state.addDL01 != null ? this.state.addDL01 : '';
                    addPss01 = this.state.addPss01 != null ? this.state.addPss01 : '';
                    break;
                case 2:
                    frontPI02 = this.state.frontPI02 != null ? this.state.frontPI02 : '';
                    endPI02 = this.state.endPI02 != null ? this.state.endPI02 : '';
                    frontDL02 = this.state.frontDL02 != null ? this.state.frontDL02 : '';
                    endDL02 = this.state.endDL02 != null ? this.state.endDL02 : '';
                    frontPss02 = this.state.frontPss02 != null ? this.state.frontPss02 : '';
                    addPI02 = this.state.addPI02 != null ? this.state.addPI02 : '';
                    addDL02 = this.state.addDL02 != null ? this.state.addDL02 : '';
                    addPss02 = this.state.addPss02 != null ? this.state.addPss02 : '';
                    break;
                case 3:
                    frontPI03 = this.state.frontPI03 != null ? this.state.frontPI03 : '';
                    endPI03 = this.state.endPI03 != null ? this.state.endPI03 : '';
                    frontDL03 = this.state.frontDL03 != null ? this.state.frontDL03 : '';
                    endDL03 = this.state.endDL03 != null ? this.state.endDL03 : '';
                    frontPss03 = this.state.frontPss03 != null ? this.state.frontPss03 : '';
                    addPI03 = this.state.addPI03 != null ? this.state.addPI03 : '';
                    addDL03 = this.state.addDL03 != null ? this.state.addDL03 : '';
                    addPss03 = this.state.addPss03 != null ? this.state.addPss03 : '';
                    break;
                case 4:
                    frontPI04 = this.state.frontPI04 != null ? this.state.frontPI04 : '';
                    endPI04 = this.state.endPI04 != null ? this.state.endPI04 : '';
                    frontDL04 = this.state.frontDL04 != null ? this.state.frontDL04 : '';
                    endDL04 = this.state.endDL04 != null ? this.state.endDL04 : '';
                    frontPss04 = this.state.frontPss04 != null ? this.state.frontPss04 : '';
                    addPI04 = this.state.addPI04 != null ? this.state.addPI04 : '';
                    addDL04 = this.state.addDL04 != null ? this.state.addDL04 : '';
                    addPss04 = this.state.addPss04!= null ? this.state.addPss04 : '';
                    break;
                case 5:
                    frontPI05 = this.state.frontPI05 != null ? this.state.frontPI05 : '';
                    endPI05 = this.state.endPI05 != null ? this.state.endPI05 : '';
                    frontDL05 = this.state.frontDL05 != null ? this.state.frontDL05 : '';
                    endDL05 = this.state.endDL05 != null ? this.state.endDL05 : '';
                    frontPss05 = this.state.frontPss05 != null ? this.state.frontPss05 : '';
                    addPI05 = this.state.addPI05 != null ? this.state.addPI05 : '';
                    addDL05 = this.state.addDL05 != null ? this.state.addDL05 : '';
                    addPss05 = this.state.addPss05 != null ? this.state.addPss05 : '';
                    break;
                default:
                    break;
            }
        }

        let lookUpList = [];
        if (this.context.lookUpTableList['PersonIdentificationType'].length > 0) {
            this.context.lookUpTableList['PersonIdentificationType'].forEach((value, index) => {
                if (index < 4) {
                    lookUpList.push(value);
                }
            })
        }

        if (lookUpList.length > 0) {
            return (
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <div id="traders_wrapper" className="">
                        {/*TRADER 01 */}
                        <div id="trader_01" className="background-style-2 trader-item">
                            <fieldset>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 form-group" style={{ textAlign: 'right' }}>
                                                <h5>
                                                    {/* <button id="invite-trader-1"
                                                        className="btn btn-primary"
                                                        rel="#trader_01"
                                                        type="button"
                                                            style={{ float: 'right' }}
                                                        onClick={this.SendOwnerEmail}>Invite a Primary Contact
                                                    </button> */}
                                                </h5>
                                            </div>
                                        </div>
                                        {getCurrentCompanyName() === 'Xushi' && <div className="row">
                                            <div className={"col-sm-3 col-xs-12" + formatValidInput(this.state.validations.SalutationID01)}>
                                                <DynamicSelect
                                                    autoFocus={true}
                                                    className="form-control use-select2 select2-selection__placeholder" id="salutation_01" name="salutation_01" style={{ width: "100%" }}
                                                    data-select2-type="simple"
                                                    data-select2-placeholder="Select ..."
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required."
                                                    objValue={{ value: this.state.SalutationID01, name: this.state.SalutationName01 }}
                                                    getValue={(value) => this.handleUpdateSalutation(1, value)}
                                                    listHasPlaceholder={true}
                                                    data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Salutation'] : []} />
                                                <label for="salutation_01" className="small">{getResource('Label_Salutation')}</label>
                                                {(this.state.validations.SalutationID01 != null && !this.state.validations.SalutationID01) &&
                                                    <label id="salutation_01-error" class="error" for="salutation_01">Field Required.</label>
                                                }
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.FirstName01)}>
                                                <input type="text"
                                                    className="form-control"
                                                    id="first_name01"
                                                    name="first_name01"
                                                    maxLength="70"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required."
                                                    value={this.state.FirstName01}
                                                    onChange={this.handleUpdateFirstName.bind(this, 1)} />
                                                <label htmlFor="first_name01" className="small">{getResource('Label_FirstName')} *</label>
                                                {(this.state.validations.FirstName01 != null && !this.state.validations.FirstName01) &&
                                                    <label id="first_name-error01" class="error" htmlFor="first_name01">Field Required.</label>
                                                }
                                            </div>
                                            {/* <div className="col-sm-4 col-xs-12 form-group">
                                                <input type="text" className="form-control" id="short_name01" name="short_name01"
                                                    maxLength="50" value={this.state.ShortName01}
                                                    onChange={this.handleUpdateShortName.bind(this, 1)} />
                                                <label htmlFor="short_name01" className="small">{getResource('Label_MiddleName')}</label>
                                            </div> */}
                                            <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.LastName01)}>
                                                <input type="text" className="form-control" id="last_name01" name="last_name01" maxLength="70"
                                                    data-rule-required="true"
                                                    data-msg-required="Required"
                                                    value={this.state.LastName01}
                                                    onChange={this.handleUpdateLastName.bind(this, 1)} />
                                                <label htmlFor="last_name01" className="small">{getCurrentCompanyName() !== 'Xushi' ? getResource('Label_Surname') : getResource('Label_LastName')} *</label>
                                                {(this.state.validations.LastName01 != null && !this.state.validations.LastName01) &&
                                                    <label id="last_name-error01" class="error" htmlFor="last_name01">Field Required.</label>
                                                }
                                            </div>
                                        </div>{/* .row */}
                                        <div className="row">
                                            <div className={"col-sm-6 col-xs-12 form-group  relative col-middle " + formatValidInput(this.state.validations.DateOfBirth01)}>
                                                <DatePicker
                                                    className="form-control field-datepicker"
                                                    readOnly={true}
                                                    endDate={today}
                                                    id="date-of-birth01"
                                                    name="date-of-birth01"
                                                    value={this.state.DateOfBirth01}
                                                    onDayChange={this.handleUpdateDateOfBirth.bind(this, 1)} />

                                                <label htmlFor="date_of_birth01" className="small">{getResource('Label_DateOfBirth')}*</label>
                                                {(this.state.validations.DateOfBirth01 != null && !this.state.validations.DateOfBirth01) &&
                                                    <label id="date_of_birth-error01" class="error" htmlFor="date_of_birth01">Field Required.</label>
                                                }
                                            </div>
                                            {getCurrentCompanyName() === 'Xushi' && <div className={"col-sm-4 col-xs-12" + formatValidInput(this.state.validations.CitizenshipID01)}>
                                                <DynamicSelect
                                                    className="form-control use-select2 select2-selection__placeholder" id="Citizenship01" name="Citizenship01" style={{ width: "100%" }}
                                                    data-select2-type="simple"
                                                    data-select2-paging="false"
                                                    data-select2-placeholder="Select Citizenship"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required"
                                                    objValue={{ value: this.state.CitizenshipID01, name: this.state.CitizenshipName01 }}
                                                    getValue={this.handleUpdateCitizenship.bind(this, 1)}
                                                    listHasPlaceholder={false}
                                                    placeholder={"Select Citizenship"}
                                                    data={citizenship != null ? citizenship : []} />
                                                <label for="Citizenship01" className="small">{getResource('Label_Citizenship')} *</label>
                                                {(this.state.validations.CitizenshipID01 != null && !this.state.validations.CitizenshipID01) &&
                                                    <label id="Citizenship-error01" class="error" for="Citizenship01">Field Required.</label>
                                                }
                                            </div>}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 form-group">
                                                <h5><b>{getResource('Subtitle_ResidentialAddress')}</b></h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={"col-md-12" + formatValidInput(this.state.validations.ResidentialAddressStreet01)}>
                                                <input type="text" className="form-control" id="IDType01" name="IDType01" maxLength="100"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required."
                                                    value={this.state.ResidentialAddressStreet01}
                                                    onChange={this.handleUpdateResidentialAddressStreet.bind(this, 1)} />
                                                <label htmlFor="IDType01" className="small">{getResource('Label_StreetAddress')}*</label>
                                                {(this.state.validations.ResidentialAddressStreet01 != null && !this.state.validations.ResidentialAddressStreet01) &&
                                                    <label id="IDType-error01" class="error" htmlFor="IDType01">Field Required.</label>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/**------------------NEW----------------- */}
                                            <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressCity01)}>
                                                {/* <DynamicSelect
                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_city" name="residential_address_city" style={{ width: "100%" }}
                                        data-select2-type="simple"
                                        data-select2-paging="false"
                                        data-select2-placeholder="Select City"
                                        data-rule-required="true"
                                        data-msg-required="Field Required"
                                        objValue={{ value: this.state.ResidentialCityId, name: this.state.ResidentialCityName }}
                                        getValue={this.handleUpdateResidentialAddressCity}
                                        listHasPlaceholder={false}
                                        placeholder={"Select City"}
                                        data={countries != null ? countries : []} />
                                    <label for="residential_address_city" className="small">{getResource('Label_City')}</label>
                                    {(this.state.validations.ResidentialCityId != null && !this.state.validations.ResidentialCityId) &&
                                        <label id="residential_address_city-error" class="error" for="residential_address_city">Field Required.</label>
                                    } */}

                                                <input type="text" className="form-control" id="residential_address_city01" name="residential_address_city0101" maxLength="100"
                                                    value={this.state.ResidentialAddressCity01}
                                                    onChange={this.handleUpdateResidentialAddressCity.bind(this, 1)} />
                                                <label htmlFor="residential_address_city01" className="small">{getResource('Label_City')}*</label>
                                                {(this.state.validations.ResidentialAddressCity01 != null && !this.state.validations.ResidentialAddressCity01) &&
                                                    <label id="residential_address_city-error01" class="error" htmlFor="residential_address_city01">Field Required.</label>
                                                }
                                            </div>
                                            <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID01)}>
                                                <DynamicSelect
                                                    className="form-control use-select2 select2-selection__placeholder" id="residential_address_country01" name="residential_address_country01" style={{ width: "100%" }}
                                                    data-select2-type="simple"
                                                    data-select2-paging="false"
                                                    data-select2-placeholder="Select Country"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required"
                                                    objValue={{ value: this.state.countryID01, name: this.state.countryName01 }}
                                                    getValue={(event) => this.handleUpdateCountry(1, event)}
                                                    listHasPlaceholder={false}
                                                    placeholder={"Select Country"}
                                                    data={countries != null ? countries : []} />
                                                <label htmlFor="residential_address_country01" className="small">{getResource('Label_Country')} *</label>
                                                {(this.state.validations.countryID01 != null && !this.state.validations.countryID01) &&
                                                    <label id="residential_address_country-error01" class="error" htmlFor="residential_address_country01">Field Required.</label>
                                                }
                                            </div> {/* .col-md-6 */}
                                        </div> {/* .row */}
                                        <div className="row">
                                            <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressStateId01)}>
                                                {
                                                    this.state.statesByCountryID01.length > 0 ?
                                                        <DynamicSelect
                                                            className="form-control use-select2 select2-selection__placeholder" id="residential_address_state01" name="residential_address_state01" style={{ width: "100%" }}
                                                            data-select2-type="simple"
                                                            data-select2-paging="false"
                                                            data-select2-placeholder="Select State"
                                                            data-rule-required="true"
                                                            data-msg-required="Field Required"
                                                            objValue={{ value: this.state.ResidentialAddressStateId01, name: this.state.ResidentialAddressStateName01 }}
                                                            getValue={this.handleUpdateResidentialAddressState.bind(this, 1)}
                                                            listHasPlaceholder={false}
                                                            placeholder={"Select State"}
                                                            data={this.state.statesByCountryID01} />
                                                        :
                                                        <input type="text" className="form-control"
                                                            id="residential_address_postalcode01"
                                                            name="residential_address_postalcode01" maxLength="50"
                                                            data-rule-required="true"
                                                            data-msg-required="Field Required."
                                                            value={this.state.OtherState01}
                                                            onChange={this.handleUpdateState.bind(this, 1)} />
                                                }

                                                <label htmlFor="residential_address_state01" className="small">{getResource('Label_State')} *</label>
                                                {(this.state.validations.ResidentialAddressStateId01 != null && !this.state.validations.ResidentialAddressStateId01) &&
                                                    <label id="residential_address_state_name-error01" class="error" htmlFor="residential_address_state_name01">Field Required.</label>
                                                }
                                            </div>
                                            <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressPostalCode01)}>
                                                <input type="text" className="form-control" id="residential_address_postalcode01" name="residential_address_postalcode01" maxLength="50"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required."
                                                    value={this.state.ResidentialAddressPostalCode01}
                                                    onChange={this.handleUpdateResidentialAddressPostalCode.bind(this, 1)} />
                                                <label htmlFor="residential_address_postalcode01" className="small">{getResource('Label_Postal')}*</label>
                                                {(this.state.validations.ResidentialAddressPostalCode01 != null && !this.state.validations.ResidentialAddressPostalCode01) &&
                                                    <label id="residential_address_postalcode-error01" class="error" htmlFor="residential_address_postalcode01">Field Required.</label>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 form-group">
                                                <h5><b>Owner Info</b></h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={"col-sm-6 col-xs-12 form-group"}>
                                                <PhoneInput
                                                    autoFormat
                                                    enableSearch
                                                    placeholder="Enter phone number"
                                                    id="phone-number-01"
                                                    name="phone-number-01"
                                                    country={defaultMobileCountry}
                                                    copyNumbersOnly={false}
                                                    countryCodeEditable={false}
                                                    value={this.state.ResidentialPhoneNumber01}
                                                    onChange={this.handleUpdateResidentialPhoneNumber.bind(this, 1)}
                                                />
                                                <label htmlFor="residential_phone_number01" className="small">Owner Number</label>
                                            </div>
                                            <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.EmailAddress01)}>
                                                <input type="email" className="form-control" id="email_address01" name="email_address01" maxLength="150"
                                                    data-rule-required="true"
                                                    data-msg-required="Field Required"
                                                    data-rule-email="true"
                                                    data-msg-email="Email Required"
                                                    value={this.state.EmailAddress01}
                                                    onChange={this.handleUpdateEmailAddress.bind(this, 1)} />
                                                <label htmlFor="email_address01" className="small">{getResource('Label_Email')}*</label>
                                                {(this.state.validations.EmailAddress01 != null && !this.state.validations.EmailAddress01) &&
                                                    <label id="email_address-error01" class="error" htmlFor="email_address01">Field Required.</label>
                                                }
                                            </div>
                                            {/*<div className="col-sm-6 col-xs-12 form-group">
                                                <input type="text" className="form-control" id="SSN01" name="SSN01" maxLength="100"
                                                    value={this.state.SSN01}
                                                    onChange={this.handleUpdateSSN.bind(this, 1)} />
                                                <label htmlFor="SSN01" className="small">{getResource('Label_SSN')}</label>
                                            </div>*/}
                                        </div> {/* .row */}
                                        <div className="row">
                                            <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.PercentageOwnership01)}>
                                                <NumberInput
                                                    id="PercentageOwnership01"
                                                    type="Integer"
                                                    className="form-control"
                                                    value={this.state.PercentageOwnership01}
                                                    min={25}
                                                    max={100}
                                                    onChange={this.handlePercentageOwnership.bind(this, 1)}
                                                />
                                                <label htmlFor="PercentageOwnership01" className="small">Percentage Ownership*</label>
                                                {(this.state.validations.PercentageOwnership01 != null && !this.state.validations.PercentageOwnership01) &&
                                                    <label id="PercentageOwnership-error01" class="error" htmlFor="PercentageOwnership01">Field Required.</label>
                                                }
                                            </div>
                                        </div>
                                {getCurrentCompanyName() === 'Xushi' && <div className='row'>
                                    <div className={"col-sm-12 col-xs-12" + formatValidInput(this.state.validations.PEP01)}>
                                        <label className="description" htmlFor="politically_exposed_person_01">
                                        <input id="politically_exposed_person_01" name="politically_exposed_person_01"
                                            data-rule-required="true"
                                            data-msg-required="Field Required."
                                            type="checkbox"
                                            checked={!this.state.PEP01}
                                            onClick={(event) => this.handleUpdatePEP(1, event)} />{getResource('Label_PoliticallyExposed2')}
                                        </label>
                                        {((this.state.validations.PEP01 != null && !this.state.validations.PEP01)) &&
                                            <label id="politically_exposed_person_01-error" class="error" for="politically_exposed_person_01">Field Required.</label>
                                        }
                                        <h6 style={{ fontWeight: 'normal' }}>{getResource('PEP_Paragraph')}</h6>
                                    </div>
                                </div>}
                                {getCurrentCompanyName() === 'Xushi' && this.state.Roles01.includes('Director') && <div className='row'>
                                    <div className={"col-sm-12 col-xs-12" + formatValidInput(this.state.validations.EasyPayAuthorised01)}>
                                        <label className="description" htmlFor="easypay_authorized_01">
                                        <input id="easypay_authorized_01" name="easypay_authorized_01"
                                            data-rule-required="true"
                                            data-msg-required="Field Required."
                                            type="checkbox"
                                            checked={this.state.EasyPayAuthorised01}
                                            onClick={(event) => this.handleUpdateEasyPayAuthorised(1, event)} />{getResource('Label_EasyPayAuthorised')}
                                        </label>
                                        {((this.state.validations.EasyPayAuthorised01 != null && !this.state.validations.EasyPayAuthorised01)) &&
                                            <label id="easypay_authorized_01-error" class="error" for="easypay_authorized_01">Field Required.</label>
                                        }
                                    </div>
                                </div>}

                                        {/* <ContactAdditionalInformation
                                            key={'primaryContact01'}
                                            notFirstLoad={this.props.notFirstLoad}
                                            totalList={this.state.allAdditionalPropertiesDefs1}
                                            customerAdditionalProperties={this.state.contactAdditionalProperties01}
                                            values={this.state.valuesOfAdditionalPropertiesDefs1}
                                            validations={this.state.validationsOfAdditionalPropertiesDefs1}
                                            onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                            step={5}
                                            principal={1}
                                        /> */}

                                        <div className="mrg-t-10p" >
                                            <div className="row">
                                                <div className="col-sm-2 col-xs-12 ">
                                                    <button id="add-trader-2" className="btn btn-primary"
                                                        rel="#trader_02" type="button"
                                                        onClick={() => this.AddClick(2, true)}>ADD ANOTHER OWNER
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        {/* END TRADER 01 */}

                        {/* TRADER 02 */}
                        {this.state.trader02 &&
                            <div id="trader_02" className="background-style-2 trader-item">
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <div className="row">
                                                <div className="col-md-12 form-group" style={{ textAlign: 'right' }}>
                                                    <button id="remove-trader-2" className="btn btn-primary"
                                                        rel="#trader_02" type="button"
                                                        onClick={() => this.RemoveClick(2, false)}
                                                        style={{ float: 'right' }}>Remove
								                    </button>
                                                </div>
                                            </div>
                                            {getCurrentCompanyName() === 'Xushi' && <div className="row">
                                                <div className={"col-sm-3 col-xs-12" + formatValidInput(this.state.validations.SalutationID02)}>
                                                    <DynamicSelect
                                                        autoFocus={true}
                                                        className="form-control use-select2 select2-selection__placeholder" id="salutation_02" name="salutation_02" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-placeholder="Select ..."
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        objValue={{ value: this.state.SalutationID02, name: this.state.SalutationName02 }}
                                                        getValue={(value) => this.handleUpdateSalutation(2, value)}
                                                        listHasPlaceholder={true}
                                                        data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Salutation'] : []} />
                                                    <label for="salutation_02" className="small">{getResource('Label_Salutation')}</label>
                                                    {(this.state.validations.SalutationID02 != null && !this.state.validations.SalutationID02) &&
                                                        <label id="salutation_02-error" class="error" for="salutation_02">Field Required.</label>
                                                    }
                                                </div>
                                            </div>}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.FirstName02)}>
                                                    <input type="text"
                                                        className="form-control"
                                                        id="first_name02"
                                                        name="first_name02"
                                                        maxLength="70"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.FirstName02}
                                                        onChange={this.handleUpdateFirstName.bind(this, 2)} />
                                                    <label htmlFor="first_name02" className="small">{getResource('Label_FirstName')} *</label>
                                                    {(this.state.validations.FirstName02 != null && !this.state.validations.FirstName02) &&
                                                        <label id="first_name-error02" class="error" htmlFor="first_name02">Field Required.</label>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-4 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="short_name02" name="short_name02"
                                                        maxLength="50" value={this.state.ShortName02}
                                                        onChange={this.handleUpdateShortName.bind(this, 2)} />
                                                    <label htmlFor="short_name02" className="small">{getResource('Label_MiddleName')}</label>
                                                </div> */}
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.LastName02)}>
                                                    <input type="text" className="form-control" id="last_name02" name="last_name02" maxLength="70"
                                                        data-rule-required="true"
                                                        data-msg-required="Required"
                                                        value={this.state.LastName02}
                                                        onChange={this.handleUpdateLastName.bind(this, 2)} />
                                                    <label htmlFor="last_name" className="small">{getResource('Label_Surname')} *</label>
                                                    {(this.state.validations.LastName02 != null && !this.state.validations.LastName02) &&
                                                        <label id="last_name-error02" class="error" htmlFor="last_name02">Field Required.</label>
                                                    }
                                                </div>
                                            </div>{/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12 form-group  relative col-middle " + formatValidInput(this.state.validations.DateOfBirth02)}>
                                                    <DatePicker
                                                        className="form-control field-datepicker"
                                                        id="date-of-birth-02"
                                                        name="date-of-birth-02"
                                                        readOnly={true}
                                                        endDate={today}
                                                        value={this.state.DateOfBirth02}
                                                        onDayChange={this.handleUpdateDateOfBirth.bind(this, 2)} />

                                                    <label htmlFor="date_of_birth02" className="small">{getResource('Label_DateOfBirth')}*</label>
                                                    {(this.state.validations.DateOfBirth02 != null && !this.state.validations.DateOfBirth02) &&
                                                        <label id="date_of_birth-error02" class="error" htmlFor="date_of_birth02">Field Required.</label>
                                                    }
                                                </div>
                                                {getCurrentCompanyName() === 'Xushi' && <div className={"col-sm-4 col-xs-12" + formatValidInput(this.state.validations.CitizenshipID02)}>
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="Citizenship02" name="Citizenship02" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select Citizenship"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.CitizenshipID02, name: this.state.CitizenshipName02 }}
                                                        getValue={this.handleUpdateCitizenship.bind(this, 2)}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select Citizenship"}
                                                        data={citizenship != null ? citizenship : []} />
                                                    <label for="Citizenship02" className="small">{getResource('Label_Citizenship')} *</label>
                                                    {(this.state.validations.CitizenshipID02 != null && !this.state.validations.CitizenshipID02) &&
                                                        <label id="Citizenship-error02" class="error" for="Citizenship02">Field Required.</label>
                                                    }
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>{getResource('Subtitle_ResidentialAddress')}</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"col-md-12" + formatValidInput(this.state.validations.ResidentialAddressStreet02)}>
                                                    <input type="text" className="form-control" id="IDType02" name="IDType02" maxLength="100"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.ResidentialAddressStreet02}
                                                        onChange={this.handleUpdateResidentialAddressStreet.bind(this, 2)} />
                                                    <label htmlFor="IDType02" className="small">{getResource('Label_StreetAddress')}*</label>
                                                    {(this.state.validations.ResidentialAddressStreet02 != null && !this.state.validations.ResidentialAddressStreet02) &&
                                                        <label id="IDType-error02" class="error" htmlFor="IDType02">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/**------------------NEW----------------- */}
                                                <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressCity02)}>
                                                    <input type="text" className="form-control" id="residential_address_city02" name="residential_address_city0102" maxLength="100"
                                                        value={this.state.ResidentialAddressCity02}
                                                        onChange={this.handleUpdateResidentialAddressCity.bind(this, 2)} />
                                                    <label htmlFor="residential_address_city02" className="small">{getResource('Label_City')}*</label>
                                                    {(this.state.validations.ResidentialAddressCity02 != null && !this.state.validations.ResidentialAddressCity02) &&
                                                        <label id="residential_address_city-error02" class="error" htmlFor="residential_address_city02">Field Required.</label>
                                                    }
                                                </div>
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID02)}>
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_country02" name="residential_address_country02" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select Country"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.countryID02, name: this.state.countryName02 }}
                                                        getValue={(event) => this.handleUpdateCountry(2, event)}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select Country"}
                                                        data={countries != null ? countries : []} />
                                                    <label htmlFor="residential_address_country02" className="small">{getResource('Label_Country')} *</label>
                                                    {(this.state.validations.countryID02 != null && !this.state.validations.countryID02) &&
                                                        <label id="residential_address_country-error02" class="error" htmlFor="residential_address_country02">Field Required.</label>
                                                    }
                                                </div> {/* .col-md-6 */}
                                            </div> {/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressStateId02)}>
                                                    {
                                                        this.state.statesByCountryID02.length > 0 ?
                                                            <DynamicSelect
                                                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_state02" name="residential_address_state02" style={{ width: "100%" }}
                                                                data-select2-type="simple"
                                                                data-select2-paging="false"
                                                                data-select2-placeholder="Select State"
                                                                data-rule-required="true"
                                                                data-msg-required="Field Required"
                                                                objValue={{ value: this.state.ResidentialAddressStateId02, name: this.state.ResidentialAddressStateName02 }}
                                                                getValue={this.handleUpdateResidentialAddressState.bind(this, 2)}
                                                                listHasPlaceholder={false}
                                                                placeholder={"Select State"}
                                                                data={this.state.statesByCountryID02} />
                                                            :
                                                            <input type="text" className="form-control"
                                                                id="residential_address_postalcode02"
                                                                name="residential_address_postalcode02" maxLength="50"
                                                                data-rule-required="true"
                                                                data-msg-required="Field Required."
                                                                value={this.state.OtherState02}
                                                                onChange={this.handleUpdateState.bind(this, 2)} />
                                                    }

                                                    <label htmlFor="residential_address_state02" className="small">{getResource('Label_State')} *</label>
                                                    {(this.state.validations.ResidentialAddressStateId02 != null && !this.state.validations.ResidentialAddressStateId02) &&
                                                        <label id="residential_address_state_name-error02" class="error" htmlFor="residential_address_state_name02">Field Required.</label>
                                                    }
                                                </div>
                                                <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressPostalCode02)}>
                                                    <input type="text" className="form-control" id="residential_address_postalcode02" name="residential_address_postalcode02" maxLength="50"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.ResidentialAddressPostalCode02}
                                                        onChange={this.handleUpdateResidentialAddressPostalCode.bind(this, 2)} />
                                                    <label htmlFor="residential_address_postalcode02" className="small">{getResource('Label_Postal')}*</label>
                                                    {(this.state.validations.ResidentialAddressPostalCode02 != null && !this.state.validations.ResidentialAddressPostalCode02) &&
                                                        <label id="residential_address_postalcode-error02" class="error" htmlFor="residential_address_postalcode02">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>Owner Info</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12 form-group"}>
                                                    <PhoneInput
                                                        autoFormat
                                                        enableSearch
                                                        id="phone-number-02"
                                                        name="phone-number-02"
                                                        placeholder="Enter phone number"
                                                        country={defaultMobileCountry}
                                                        copyNumbersOnly={false}
                                                        countryCodeEditable={false}
                                                        value={this.state.ResidentialPhoneNumber02}
                                                        onChange={this.handleUpdateResidentialPhoneNumber.bind(this, 2)}
                                                    />
                                                    <label htmlFor="residential_phone_number02" className="small">Owner Number </label>
                                                </div>
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.EmailAddress02)}>
                                                    <input type="email" className="form-control" id="email_address02" name="email_address02" maxLength="150"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        data-rule-email="true"
                                                        data-msg-email="Email Required"
                                                        value={this.state.EmailAddress02}
                                                        onChange={this.handleUpdateEmailAddress.bind(this, 2)} />
                                                    <label htmlFor="email_address02" className="small">{getResource('Label_Email')}*</label>
                                                    {(this.state.validations.EmailAddress02 != null && !this.state.validations.EmailAddress02) &&
                                                        <label id="email_address-error02" class="error" htmlFor="email_address02">Field Required.</label>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-6 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="SSN02" name="SSN02" maxLength="100"
                                                        value={this.state.SSN02}
                                                        onChange={this.handleUpdateSSN.bind(this, 2)} />
                                                    <label htmlFor="SSN02" className="small">{getResource('Label_SSN')}</label>
                                                </div> */}
                                            </div> {/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.PercentageOwnership02)}>
                                                    <NumberInput
                                                        id="PercentageOwnership02"
                                                        type="Integer"
                                                        className="form-control"
                                                        value={this.state.PercentageOwnership02}
                                                        min={25}
                                                        max={100}
                                                        onChange={this.handlePercentageOwnership.bind(this, 2)}
                                                    />
                                                    <label htmlFor="PercentageOwnership01" className="small">Percentage Ownership*</label>
                                                    {(this.state.validations.PercentageOwnership02 != null && !this.state.validations.PercentageOwnership02) &&
                                                        <label id="PercentageOwnership-error02" class="error" htmlFor="PercentageOwnership02">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                {getCurrentCompanyName() === 'Xushi' && <div className='row'>
                                    <div className={"col-sm-12 col-xs-12" + formatValidInput(this.state.validations.PEP02)}>
                                        <label className="description" htmlFor="politically_exposed_person_02">
                                        <input id="politically_exposed_person_02" name="politically_exposed_person_02"
                                            data-rule-required="true"
                                            data-msg-required="Field Required."
                                            type="checkbox"
                                            checked={!this.state.PEP02}
                                            onClick={(event) => this.handleUpdatePEP(2, event)} />{getResource('Label_PoliticallyExposed2')}
                                        </label>
                                        {((this.state.validations.PEP02 != null && !this.state.validations.PEP02)) &&
                                            <label id="politically_exposed_person_02-error" class="error" for="politically_exposed_person_02">Field Required.</label>
                                        }
                                        <h6 style={{ fontWeight: 'normal' }}>{getResource('PEP_Paragraph')}</h6>
                                    </div>
                                </div>}
                                {getCurrentCompanyName() === 'Xushi' && this.state.Roles02.includes('Director') && <div className='row'>
                                    <div className={"col-sm-12 col-xs-12" + formatValidInput(this.state.validations.EasyPayAuthorised02)}>
                                        <label className="description" htmlFor="easypay_authorized_02">
                                        <input id="easypay_authorized_02" name="easypay_authorized_02"
                                            data-rule-required="true"
                                            data-msg-required="Field Required."
                                            type="checkbox"
                                            checked={this.state.EasyPayAuthorised02}
                                            onClick={(event) => this.handleUpdateEasyPayAuthorised(2, event)} />{getResource('Label_EasyPayAuthorised')}
                                        </label>
                                        {((this.state.validations.EasyPayAuthorised02 != null && !this.state.validations.EasyPayAuthorised02)) &&
                                            <label id="easypay_authorized_02-error" class="error" for="easypay_authorized_02">Field Required.</label>
                                        }
                                    </div>
                                </div>}

                                            {/* <ContactAdditionalInformation
                                                key={'primaryContact02'}
                                                notFirstLoad={this.props.notFirstLoad}
                                                totalList={this.state.allAdditionalPropertiesDefs2}
                                                customerAdditionalProperties={this.state.contactAdditionalProperties02}
                                                values={this.state.valuesOfAdditionalPropertiesDefs2}
                                                validations={this.state.validationsOfAdditionalPropertiesDefs2}
                                                onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                                step={5}
                                                principal={2}
                                            /> */}
                                            <div className="mrg-t-10p" >
                                                <div className="row">
                                                    <div className="col-sm-2 col-xs-12 ">
                                                        <button id="add-trader-3" className="btn btn-primary"
                                                            rel="#trader_03" type="button"
                                                            onClick={() => this.AddClick(3, true)}>ADD ANOTHER OWNER
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        }
                        {/* END TRADER 02 */}

                        {/* TRADER 03 */}
                        {this.state.trader03 &&
                            <div id="trader_03" className="background-style-2 trader-item">
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <div className="row">
                                                <div className="col-md-12 form-group" style={{ textAlign: 'right' }}>
                                                    <button id="remove-trader-3" className="btn btn-primary"
                                                        rel="#trader_03" type="button"
                                                        onClick={() => this.RemoveClick(3, false)}
                                                        style={{ float: 'right' }}>Remove
								                    </button>
                                                </div>
                                            </div>
                                            {getCurrentCompanyName() === 'Xushi' && <div className="row">
                                                <div className={"col-sm-3 col-xs-12" + formatValidInput(this.state.validations.SalutationID03)}>
                                                    <DynamicSelect
                                                        autoFocus={true}
                                                        className="form-control use-select2 select2-selection__placeholder" id="salutation_03" name="salutation_03" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-placeholder="Select ..."
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        objValue={{ value: this.state.SalutationID03, name: this.state.SalutationName03 }}
                                                        getValue={(value) => this.handleUpdateSalutation(3, value)}
                                                        listHasPlaceholder={true}
                                                        data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Salutation'] : []} />
                                                    <label for="salutation_03" className="small">{getResource('Label_Salutation')}</label>
                                                    {(this.state.validations.SalutationID03 != null && !this.state.validations.SalutationID03) &&
                                                        <label id="salutation_03-error" class="error" for="salutation_03">Field Required.</label>
                                                    }
                                                </div>
                                            </div>}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.FirstName03)}>
                                                    <input type="text"
                                                        className="form-control"
                                                        id="first_name03"
                                                        name="first_name03"
                                                        maxLength="70"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.FirstName03}
                                                        onChange={this.handleUpdateFirstName.bind(this, 3)} />
                                                    <label htmlFor="first_name03" className="small">{getResource('Label_FirstName')} *</label>
                                                    {(this.state.validations.FirstName03 != null && !this.state.validations.FirstName03) &&
                                                        <label id="first_name-error03" class="error" htmlFor="first_name03">Field Required.</label>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-4 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="short_name03" name="short_name03"
                                                        maxLength="50" value={this.state.ShortName03}
                                                        onChange={this.handleUpdateShortName.bind(this, 3)} />
                                                    <label htmlFor="short_name03" className="small">{getResource('Label_MiddleName')}</label>
                                                </div> */}
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.LastName03)}>
                                                    <input type="text" className="form-control" id="last_name03" name="last_name03" maxLength="70"
                                                        data-rule-required="true"
                                                        data-msg-required="Required"
                                                        value={this.state.LastName03}
                                                        onChange={this.handleUpdateLastName.bind(this, 3)} />
                                                    <label htmlFor="last_name" className="small">{getResource('Label_Surname')} *</label>
                                                    {(this.state.validations.LastName03 != null && !this.state.validations.LastName03) &&
                                                        <label id="last_name-error03" class="error" htmlFor="last_name03">Field Required.</label>
                                                    }
                                                </div>
                                            </div>{/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12 form-group  relative col-middle " + formatValidInput(this.state.validations.DateOfBirth03)}>
                                                    <DatePicker
                                                        className="form-control field-datepicker"
                                                        id="date-of-birth-03"
                                                        name="date-of-birth-03"
                                                        readOnly={true}
                                                        endDate={today}
                                                        value={this.state.DateOfBirth03}
                                                        onDayChange={this.handleUpdateDateOfBirth.bind(this, 3)} />

                                                    <label htmlFor="date_of_birth03" className="small">{getResource('Label_DateOfBirth')}*</label>
                                                    {(this.state.validations.DateOfBirth03 != null && !this.state.validations.DateOfBirth03) &&
                                                        <label id="date_of_birth-error03" class="error" htmlFor="date_of_birth03">Field Required.</label>
                                                    }
                                                </div>
                                                {getCurrentCompanyName() === 'Xushi' && <div className={"col-sm-4 col-xs-12" + formatValidInput(this.state.validations.CitizenshipID03)}>
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="Citizenship03" name="Citizenship03" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select Citizenship"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.CitizenshipID03, name: this.state.CitizenshipName03 }}
                                                        getValue={this.handleUpdateCitizenship.bind(this, 3)}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select Citizenship"}
                                                        data={citizenship != null ? citizenship : []} />
                                                    <label for="Citizenship03" className="small">{getResource('Label_Citizenship')} *</label>
                                                    {(this.state.validations.CitizenshipID03 != null && !this.state.validations.CitizenshipID03) &&
                                                        <label id="Citizenship-error03" class="error" for="Citizenship03">Field Required.</label>
                                                    }
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>{getResource('Subtitle_ResidentialAddress')}</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"col-md-12" + formatValidInput(this.state.validations.ResidentialAddressStreet03)}>
                                                    <input type="text" className="form-control" id="IDType03" name="IDType03" maxLength="100"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.ResidentialAddressStreet03}
                                                        onChange={this.handleUpdateResidentialAddressStreet.bind(this, 3)} />
                                                    <label htmlFor="IDType03" className="small">{getResource('Label_StreetAddress')}*</label>
                                                    {(this.state.validations.ResidentialAddressStreet03 != null && !this.state.validations.ResidentialAddressStreet03) &&
                                                        <label id="IDType-error03" class="error" htmlFor="IDType03">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/**------------------NEW----------------- */}
                                                <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressCity03)}>
                                                    <input type="text" className="form-control" id="residential_address_city03" name="residential_address_city03" maxLength="100"
                                                        value={this.state.ResidentialAddressCity03}
                                                        onChange={this.handleUpdateResidentialAddressCity.bind(this, 3)} />
                                                    <label htmlFor="residential_address_city03" className="small">{getResource('Label_City')}*</label>
                                                    {(this.state.validations.ResidentialAddressCity03 != null && !this.state.validations.ResidentialAddressCity03) &&
                                                        <label id="residential_address_city-error03" class="error" htmlFor="residential_address_city03">Field Required.</label>
                                                    }
                                                </div>
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID03)}>
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_country03" name="residential_address_country03" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select Country"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.countryID03, name: this.state.countryName03 }}
                                                        getValue={(event) => this.handleUpdateCountry(3, event)}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select Country"}
                                                        data={countries != null ? countries : []} />
                                                    <label htmlFor="residential_address_country03" className="small">{getResource('Label_Country')} *</label>
                                                    {(this.state.validations.countryID03 != null && !this.state.validations.countryID03) &&
                                                        <label id="residential_address_country-error03" class="error" htmlFor="residential_address_country03">Field Required.</label>
                                                    }
                                                </div> {/* .col-md-6 */}
                                            </div> {/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressStateId03)}>
                                                    {
                                                        this.state.statesByCountryID03.length > 0 ?
                                                            <DynamicSelect
                                                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_state03" name="residential_address_state03" style={{ width: "100%" }}
                                                                data-select2-type="simple"
                                                                data-select2-paging="false"
                                                                data-select2-placeholder="Select State"
                                                                data-rule-required="true"
                                                                data-msg-required="Field Required"
                                                                objValue={{ value: this.state.ResidentialAddressStateId03, name: this.state.ResidentialAddressStateName03 }}
                                                                getValue={this.handleUpdateResidentialAddressState.bind(this, 3)}
                                                                listHasPlaceholder={false}
                                                                placeholder={"Select State"}
                                                                data={this.state.statesByCountryID03} />
                                                            :
                                                            <input type="text" className="form-control"
                                                                id="residential_address_postalcode03"
                                                                name="residential_address_postalcode03" maxLength="50"
                                                                data-rule-required="true"
                                                                data-msg-required="Field Required."
                                                                value={this.state.OtherState03}
                                                                onChange={this.handleUpdateState.bind(this, 3)} />
                                                    }

                                                    <label htmlFor="residential_address_state03" className="small">{getResource('Label_State')} *</label>
                                                    {(this.state.validations.ResidentialAddressStateId03 != null && !this.state.validations.ResidentialAddressStateId03) &&
                                                        <label id="residential_address_state_name-error03" class="error" htmlFor="residential_address_state_name03">Field Required.</label>
                                                    }
                                                </div>
                                                <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressPostalCode03)}>
                                                    <input type="text" className="form-control" id="residential_address_postalcode03" name="residential_address_postalcode03" maxLength="50"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.ResidentialAddressPostalCode03}
                                                        onChange={this.handleUpdateResidentialAddressPostalCode.bind(this, 3)} />
                                                    <label htmlFor="residential_address_postalcode03" className="small">{getResource('Label_Postal')}*</label>
                                                    {(this.state.validations.ResidentialAddressPostalCode03 != null && !this.state.validations.ResidentialAddressPostalCode03) &&
                                                        <label id="residential_address_postalcode-error03" class="error" htmlFor="residential_address_postalcode03">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>Owner Info</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12 form-group"}>
                                                    <PhoneInput
                                                        autoFormat
                                                        enableSearch
                                                        id="phone-number-03"
                                                        name="phone-number-03"
                                                        placeholder="Enter phone number"
                                                        country={defaultMobileCountry}
                                                        copyNumbersOnly={false}
                                                        countryCodeEditable={false}
                                                        value={this.state.ResidentialPhoneNumber03}
                                                        onChange={this.handleUpdateResidentialPhoneNumber.bind(this, 3)}
                                                    />
                                                    <label htmlFor="residential_phone_number03" className="small">Owner Number </label>
                                                </div>
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.EmailAddress03)}>
                                                    <input type="email" className="form-control" id="email_address03" name="email_address03" maxLength="150"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        data-rule-email="true"
                                                        data-msg-email="Email Required"
                                                        value={this.state.EmailAddress03}
                                                        onChange={this.handleUpdateEmailAddress.bind(this, 3)} />
                                                    <label htmlFor="email_address03" className="small">{getResource('Label_Email')}*</label>
                                                    {(this.state.validations.EmailAddress03 != null && !this.state.validations.EmailAddress03) &&
                                                        <label id="email_address-error03" class="error" htmlFor="email_address03">Field Required.</label>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-6 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="SSN03" name="SSN03" maxLength="100"
                                                        value={this.state.SSN03}
                                                        onChange={this.handleUpdateSSN.bind(this, 3)} />
                                                    <label htmlFor="SSN03" className="small">{getResource('Label_SSN')}</label>
                                                </div> */}
                                            </div> {/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.PercentageOwnership03)}>
                                                    <NumberInput
                                                        id="PercentageOwnership03"
                                                        type="Integer"
                                                        className="form-control"
                                                        value={this.state.PercentageOwnership03}
                                                        min={25}
                                                        max={100}
                                                        onChange={this.handlePercentageOwnership.bind(this, 3)}
                                                    />
                                                    <label htmlFor="PercentageOwnership03" className="small">Percentage Ownership*</label>
                                                    {(this.state.validations.PercentageOwnership03 != null && !this.state.validations.PercentageOwnership03) &&
                                                        <label id="PercentageOwnership-error03" class="error" htmlFor="PercentageOwnership03">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                {getCurrentCompanyName() === 'Xushi' && <div className='row'>
                                    <div className={"col-sm-12 col-xs-12" + formatValidInput(this.state.validations.PEP03)}>
                                        <label className="description" htmlFor="politically_exposed_person_03">
                                        <input id="politically_exposed_person_03" name="politically_exposed_person_03"
                                            data-rule-required="true"
                                            data-msg-required="Field Required."
                                            type="checkbox"
                                            checked={!this.state.PEP03}
                                            onClick={(event) => this.handleUpdatePEP(3, event)} />{getResource('Label_PoliticallyExposed2')}
                                        </label>
                                        {((this.state.validations.PEP03 != null && !this.state.validations.PEP03)) &&
                                            <label id="politically_exposed_person_03-error" class="error" for="politically_exposed_person_03">Field Required.</label>
                                        }
                                        <h6 style={{ fontWeight: 'normal' }}>{getResource('PEP_Paragraph')}</h6>
                                    </div>
                                </div>}
                                {getCurrentCompanyName() === 'Xushi' && this.state.Roles03.includes('Director') && <div className='row'>
                                    <div className={"col-sm-12 col-xs-12" + formatValidInput(this.state.validations.EasyPayAuthorised03)}>
                                        <label className="description" htmlFor="easypay_authorized_03">
                                        <input id="easypay_authorized_03" name="easypay_authorized_03"
                                            data-rule-required="true"
                                            data-msg-required="Field Required."
                                            type="checkbox"
                                            checked={this.state.EasyPayAuthorised03}
                                            onClick={(event) => this.handleUpdateEasyPayAuthorised(3, event)} />{getResource('Label_EasyPayAuthorised')}
                                        </label>
                                        {((this.state.validations.EasyPayAuthorised03 != null && !this.state.validations.EasyPayAuthorised03)) &&
                                            <label id="easypay_authorized_03-error" class="error" for="easypay_authorized_03">Field Required.</label>
                                        }
                                    </div>
                                </div>}


                                            {/* <ContactAdditionalInformation
                                                key={'primaryContact03'}
                                                notFirstLoad={this.props.notFirstLoad}
                                                totalList={this.state.allAdditionalPropertiesDefs3}
                                                customerAdditionalProperties={this.state.contactAdditionalProperties03}
                                                values={this.state.valuesOfAdditionalPropertiesDefs3}
                                                validations={this.state.validationsOfAdditionalPropertiesDefs3}
                                                onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                                step={5}
                                                principal={3}
                                            /> */}

                                            <div className="mrg-t-10p" >
                                                <div className="row">
                                                    <div className="col-sm-2 col-xs-12 ">
                                                        <button id="add-trader-4" className="btn btn-primary"
                                                            rel="#trader_04" type="button"
                                                            onClick={() => this.AddClick(4, true)}>ADD ANOTHER OWNER
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        }
                        {/* END TRADER 03 */}

                        {/* TRADER 04 */}
                        {this.state.trader04 &&
                            <div id="trader_04" className="background-style-2 trader-item">
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <div className="row">
                                                <div className="col-md-12 form-group" style={{ textAlign: 'right' }}>
                                                    <button id="remove-trader-4" className="btn btn-primary"
                                                        rel="#trader_04" type="button"
                                                        onClick={() => this.RemoveClick(4, false)}
                                                        style={{ float: 'right' }}>Remove
								                    </button>
                                                </div>
                                            </div>
                                            {getCurrentCompanyName() === 'Xushi' && <div className="row">
                                                <div className={"col-sm-3 col-xs-12" + formatValidInput(this.state.validations.SalutationID04)}>
                                                    <DynamicSelect
                                                        autoFocus={true}
                                                        className="form-control use-select2 select2-selection__placeholder" id="salutation_04" name="salutation_04" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-placeholder="Select ..."
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        objValue={{ value: this.state.SalutationID04, name: this.state.SalutationName04 }}
                                                        getValue={(value) => this.handleUpdateSalutation(4, value)}
                                                        listHasPlaceholder={true}
                                                        data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Salutation'] : []} />
                                                    <label for="salutation_02" className="small">{getResource('Label_Salutation')}</label>
                                                    {(this.state.validations.SalutationID04 != null && !this.state.validations.SalutationID04) &&
                                                        <label id="salutation_04-error" class="error" for="salutation_04">Field Required.</label>
                                                    }
                                                </div>
                                            </div>}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.FirstName04)}>
                                                    <input type="text"
                                                        className="form-control"
                                                        id="first_name04"
                                                        name="first_name04"
                                                        maxLength="70"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.FirstName04}
                                                        onChange={this.handleUpdateFirstName.bind(this, 4)} />
                                                    <label htmlFor="first_name04" className="small">{getResource('Label_FirstName')} *</label>
                                                    {(this.state.validations.FirstName04 != null && !this.state.validations.FirstName04) &&
                                                        <label id="first_name-error04" class="error" htmlFor="first_name04">Field Required.</label>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-4 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="short_name04" name="short_name04"
                                                        maxLength="50" value={this.state.ShortName04}
                                                        onChange={this.handleUpdateShortName.bind(this, 4)} />
                                                    <label htmlFor="short_name04" className="small">{getResource('Label_MiddleName')}</label>
                                                </div> */}
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.LastName04)}>
                                                    <input type="text" className="form-control" id="last_name04" name="last_name04" maxLength="70"
                                                        data-rule-required="true"
                                                        data-msg-required="Required"
                                                        value={this.state.LastName04}
                                                        onChange={this.handleUpdateLastName.bind(this, 4)} />
                                                    <label htmlFor="last_name" className="small">{getResource('Label_Surname')} *</label>
                                                    {(this.state.validations.LastName04 != null && !this.state.validations.LastName04) &&
                                                        <label id="last_name-error04" class="error" htmlFor="last_name04">Field Required.</label>
                                                    }
                                                </div>
                                            </div>{/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12 form-group  relative col-middle " + formatValidInput(this.state.validations.DateOfBirth04)}>
                                                    <DatePicker
                                                        className="form-control field-datepicker"
                                                        id="date-of-birth-04"
                                                        name="date-of-birth-04"
                                                        readOnly={true}
                                                        endDate={today}
                                                        value={this.state.DateOfBirth04}
                                                        onDayChange={this.handleUpdateDateOfBirth.bind(this, 4)} />

                                                    <label htmlFor="date_of_birth04" className="small">{getResource('Label_DateOfBirth')}*</label>
                                                    {(this.state.validations.DateOfBirth04 != null && !this.state.validations.DateOfBirth04) &&
                                                        <label id="date_of_birth-error04" class="error" htmlFor="date_of_birth04">Field Required.</label>
                                                    }
                                                </div>
                                                {getCurrentCompanyName() === 'Xushi' && <div className={"col-sm-4 col-xs-12" + formatValidInput(this.state.validations.CitizenshipID04)}>
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="Citizenship04" name="Citizenship04" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select Citizenship"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.CitizenshipID04, name: this.state.CitizenshipName04 }}
                                                        getValue={this.handleUpdateCitizenship.bind(this, 4)}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select Citizenship"}
                                                        data={citizenship != null ? citizenship : []} />
                                                    <label for="Citizenship04" className="small">{getResource('Label_Citizenship')} *</label>
                                                    {(this.state.validations.CitizenshipID04 != null && !this.state.validations.CitizenshipID04) &&
                                                        <label id="Citizenship-error04" class="error" for="Citizenship04">Field Required.</label>
                                                    }
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>{getResource('Subtitle_ResidentialAddress')}</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"col-md-12" + formatValidInput(this.state.validations.ResidentialAddressStreet04)}>
                                                    <input type="text" className="form-control" id="IDType04" name="IDType04" maxLength="100"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.ResidentialAddressStreet04}
                                                        onChange={this.handleUpdateResidentialAddressStreet.bind(this, 4)} />
                                                    <label htmlFor="IDType04" className="small">{getResource('Label_StreetAddress')}*</label>
                                                    {(this.state.validations.ResidentialAddressStreet04 != null && !this.state.validations.ResidentialAddressStreet04) &&
                                                        <label id="IDType-error04" class="error" htmlFor="IDType04">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/**------------------NEW----------------- */}
                                                <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressCity04)}>
                                                    <input type="text" className="form-control" id="residential_address_city04" name="residential_address_city04" maxLength="100"
                                                        value={this.state.ResidentialAddressCity04}
                                                        onChange={this.handleUpdateResidentialAddressCity.bind(this, 4)} />
                                                    <label htmlFor="residential_address_city04" className="small">{getResource('Label_City')}*</label>
                                                    {(this.state.validations.ResidentialAddressCity04 != null && !this.state.validations.ResidentialAddressCity04) &&
                                                        <label id="residential_address_city-error04" class="error" htmlFor="residential_address_city04">Field Required.</label>
                                                    }
                                                </div>
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID04)}>
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_country04" name="residential_address_country04" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select Country"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.countryID04, name: this.state.countryName04 }}
                                                        getValue={(event) => this.handleUpdateCountry(4, event)}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select Country"}
                                                        data={countries != null ? countries : []} />
                                                    <label htmlFor="residential_address_country04" className="small">{getResource('Label_Country')} *</label>
                                                    {(this.state.validations.countryID04 != null && !this.state.validations.countryID04) &&
                                                        <label id="residential_address_country-error04" class="error" htmlFor="residential_address_country04">Field Required.</label>
                                                    }
                                                </div> {/* .col-md-6 */}
                                            </div> {/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressStateId04)}>
                                                    {
                                                        this.state.statesByCountryID04.length > 0 ?
                                                            <DynamicSelect
                                                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_state04" name="residential_address_state04" style={{ width: "100%" }}
                                                                data-select2-type="simple"
                                                                data-select2-paging="false"
                                                                data-select2-placeholder="Select State"
                                                                data-rule-required="true"
                                                                data-msg-required="Field Required"
                                                                objValue={{ value: this.state.ResidentialAddressStateId04, name: this.state.ResidentialAddressStateName04 }}
                                                                getValue={this.handleUpdateResidentialAddressState.bind(this, 4)}
                                                                listHasPlaceholder={false}
                                                                placeholder={"Select State"}
                                                                data={this.state.statesByCountryID04} />
                                                            :
                                                            <input type="text" className="form-control"
                                                                id="residential_address_postalcode04"
                                                                name="residential_address_postalcode04" maxLength="50"
                                                                data-rule-required="true"
                                                                data-msg-required="Field Required."
                                                                value={this.state.OtherState04}
                                                                onChange={this.handleUpdateState.bind(this, 4)} />
                                                    }

                                                    <label htmlFor="residential_address_state04" className="small">{getResource('Label_State')} *</label>
                                                    {(this.state.validations.ResidentialAddressStateId04 != null && !this.state.validations.ResidentialAddressStateId04) &&
                                                        <label id="residential_address_state_name-error04" class="error" htmlFor="residential_address_state_name04">Field Required.</label>
                                                    }
                                                </div>
                                                <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressPostalCode04)}>
                                                    <input type="text" className="form-control" id="residential_address_postalcode04" name="residential_address_postalcode04" maxLength="50"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.ResidentialAddressPostalCode04}
                                                        onChange={this.handleUpdateResidentialAddressPostalCode.bind(this, 4)} />
                                                    <label htmlFor="residential_address_postalcode04" className="small">{getResource('Label_Postal')}*</label>
                                                    {(this.state.validations.ResidentialAddressPostalCode04 != null && !this.state.validations.ResidentialAddressPostalCode04) &&
                                                        <label id="residential_address_postalcode-error04" class="error" htmlFor="residential_address_postalcode04">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>Owner Info</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12 form-group"}>
                                                    <PhoneInput
                                                        autoFormat
                                                        enableSearch
                                                        id="phone-number-04"
                                                        name="phone-number-04"
                                                        placeholder="Enter phone number"
                                                        country={defaultMobileCountry}
                                                        copyNumbersOnly={false}
                                                        countryCodeEditable={false}
                                                        value={this.state.ResidentialPhoneNumber04}
                                                        onChange={this.handleUpdateResidentialPhoneNumber.bind(this, 4)}
                                                    />
                                                    <label htmlFor="residential_phone_number04" className="small">Owner Number </label>
                                                </div>
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.EmailAddress04)}>
                                                    <input type="email" className="form-control" id="email_address04" name="email_address04" maxLength="150"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        data-rule-email="true"
                                                        data-msg-email="Email Required"
                                                        value={this.state.EmailAddress04}
                                                        onChange={this.handleUpdateEmailAddress.bind(this, 4)} />
                                                    <label htmlFor="email_address04" className="small">{getResource('Label_Email')}*</label>
                                                    {(this.state.validations.EmailAddress04 != null && !this.state.validations.EmailAddress04) &&
                                                        <label id="email_address-error04" class="error" htmlFor="email_address04">Field Required.</label>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-6 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="SSN04" name="SSN04" maxLength="100"
                                                        value={this.state.SSN04}
                                                        onChange={this.handleUpdateSSN.bind(this, 4)} />
                                                    <label htmlFor="SSN04" className="small">{getResource('Label_SSN')}</label>
                                                </div> */}
                                            </div> {/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.PercentageOwnership04)}>
                                                    <NumberInput
                                                        id="PercentageOwnership04"
                                                        type="Integer"
                                                        className="form-control"
                                                        value={this.state.PercentageOwnership04}
                                                        min={25}
                                                        max={100}
                                                        onChange={this.handlePercentageOwnership.bind(this, 4)}
                                                    />
                                                    <label htmlFor="PercentageOwnership04" className="small">Percentage Ownership*</label>
                                                    {(this.state.validations.PercentageOwnership04 != null && !this.state.validations.PercentageOwnership04) &&
                                                        <label id="PercentageOwnership-error04" class="error" htmlFor="PercentageOwnership04">Field Required.</label>
                                                    }
                                                </div>
                                            </div>



                                            {/* <ContactAdditionalInformation
                                                key={'primaryContact04'}
                                                notFirstLoad={this.props.notFirstLoad}
                                                totalList={this.state.allAdditionalPropertiesDefs4}
                                                customerAdditionalProperties={this.state.contactAdditionalProperties04}
                                                values={this.state.valuesOfAdditionalPropertiesDefs4}
                                                validations={this.state.validationsOfAdditionalPropertiesDefs4}
                                                onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                                step={5}
                                                principal={4}
                                            /> */}

                                            <div className="mrg-t-10p" >
                                                <div className="row">
                                                    <div className="col-sm-2 col-xs-12 ">
                                                        <button id="add-trader-5" className="btn btn-primary"
                                                            rel="#trader_05" type="button"
                                                            onClick={() => this.AddClick(5, true)}>ADD ANOTHER OWNER
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        }
                        {/* END TRADER 04 */}

                        {/* TRADER 05 */}
                        {this.state.trader05 &&
                            <div id="trader_05" className="background-style-2 trader-item">
                                <fieldset>
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12">
                                            <div className="row">
                                                <div className="col-md-12 form-group" style={{ textAlign: 'right' }}>
                                                    <button id="remove-trader-5" className="btn btn-primary"
                                                        rel="#trader_05" type="button"
                                                        onClick={() => this.RemoveClick(5, false)}
                                                        style={{ float: 'right' }}>Remove
								                    </button>
                                                </div>
                                            </div>
                                            {getCurrentCompanyName() === 'Xushi' && <div className="row">
                                                <div className={"col-sm-3 col-xs-12" + formatValidInput(this.state.validations.SalutationID05)}>
                                                    <DynamicSelect
                                                        autoFocus={true}
                                                        className="form-control use-select2 select2-selection__placeholder" id="salutation_05" name="salutation_05" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-placeholder="Select ..."
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        objValue={{ value: this.state.SalutationID05, name: this.state.SalutationName05 }}
                                                        getValue={(value) => this.handleUpdateSalutation(5, value)}
                                                        listHasPlaceholder={true}
                                                        data={this.context.lookUpTableList != null ? this.context.lookUpTableList['Salutation'] : []} />
                                                    <label for="salutation_05" className="small">{getResource('Label_Salutation')}</label>
                                                    {(this.state.validations.SalutationID05 != null && !this.state.validations.SalutationID05) &&
                                                        <label id="salutation_05-error" class="error" for="salutation_05">Field Required.</label>
                                                    }
                                                </div>
                                            </div>}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.FirstName05)}>
                                                    <input type="text"
                                                        className="form-control"
                                                        id="first_name05"
                                                        name="first_name05"
                                                        maxLength="70"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.FirstName05}
                                                        onChange={this.handleUpdateFirstName.bind(this, 5)} />
                                                    <label htmlFor="first_name05" className="small">{getResource('Label_FirstName')} *</label>
                                                    {(this.state.validations.FirstName05 != null && !this.state.validations.FirstName05) &&
                                                        <label id="first_name-error05" class="error" htmlFor="first_name05">Field Required.</label>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-4 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="short_name05" name="short_name05"
                                                        maxLength="50" value={this.state.ShortName05}
                                                        onChange={this.handleUpdateShortName.bind(this, 5)} />
                                                    <label htmlFor="short_name05" className="small">{getResource('Label_MiddleName')}</label>
                                                </div> */}
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.LastName05)}>
                                                    <input type="text" className="form-control" id="last_name05" name="last_name05" maxLength="70"
                                                        data-rule-required="true"
                                                        data-msg-required="Required"
                                                        value={this.state.LastName05}
                                                        onChange={this.handleUpdateLastName.bind(this, 5)} />
                                                    <label htmlFor="last_name" className="small">{getResource('Label_Surname')} *</label>
                                                    {(this.state.validations.LastName05 != null && !this.state.validations.LastName05) &&
                                                        <label id="last_name-error05" class="error" htmlFor="last_name05">Field Required.</label>
                                                    }
                                                </div>
                                            </div>{/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12 form-group  relative col-middle " + formatValidInput(this.state.validations.DateOfBirth05)}>
                                                    <DatePicker
                                                        className="form-control field-datepicker"
                                                        id="date-of-birth-05"
                                                        name="date-of-birth-05"
                                                        readOnly={true}
                                                        endDate={today}
                                                        value={this.state.DateOfBirth05}
                                                        onDayChange={this.handleUpdateDateOfBirth.bind(this, 5)} />

                                                    <label htmlFor="date_of_birth05" className="small">{getResource('Label_DateOfBirth')}*</label>
                                                    {(this.state.validations.DateOfBirth05 != null && !this.state.validations.DateOfBirth05) &&
                                                        <label id="date_of_birth-error05" class="error" htmlFor="date_of_birth05">Field Required.</label>
                                                    }
                                                </div>
                                                {getCurrentCompanyName() === 'Xushi' && <div className={"col-sm-4 col-xs-12" + formatValidInput(this.state.validations.CitizenshipID05)}>
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="Citizenship05" name="Citizenship05" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select Citizenship"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.CitizenshipID05, name: this.state.CitizenshipName05 }}
                                                        getValue={this.handleUpdateCitizenship.bind(this, 5)}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select Citizenship"}
                                                        data={citizenship != null ? citizenship : []} />
                                                    <label for="Citizenship05" className="small">{getResource('Label_Citizenship')} *</label>
                                                    {(this.state.validations.CitizenshipID05 != null && !this.state.validations.CitizenshipID05) &&
                                                        <label id="Citizenship-error05" class="error" for="Citizenship05">Field Required.</label>
                                                    }
                                                </div>}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>{getResource('Subtitle_ResidentialAddress')}</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"col-md-12" + formatValidInput(this.state.validations.ResidentialAddressStreet05)}>
                                                    <input type="text" className="form-control" id="IDType05" name="IDType05" maxLength="100"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.ResidentialAddressStreet05}
                                                        onChange={this.handleUpdateResidentialAddressStreet.bind(this, 5)} />
                                                    <label htmlFor="IDType05" className="small">{getResource('Label_StreetAddress')}*</label>
                                                    {(this.state.validations.ResidentialAddressStreet05 != null && !this.state.validations.ResidentialAddressStreet05) &&
                                                        <label id="IDType-error05" class="error" htmlFor="IDType05">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/**------------------NEW----------------- */}
                                                <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressCity05)}>
                                                    <input type="text" className="form-control" id="residential_address_city04" name="residential_address_city05" maxLength="100"
                                                        value={this.state.ResidentialAddressCity05}
                                                        onChange={this.handleUpdateResidentialAddressCity.bind(this, 5)} />
                                                    <label htmlFor="residential_address_city05" className="small">{getResource('Label_City')}*</label>
                                                    {(this.state.validations.ResidentialAddressCity05 != null && !this.state.validations.ResidentialAddressCity05) &&
                                                        <label id="residential_address_city-error05" class="error" htmlFor="residential_address_city05">Field Required.</label>
                                                    }
                                                </div>
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.countryID05)}>
                                                    <DynamicSelect
                                                        className="form-control use-select2 select2-selection__placeholder" id="residential_address_country05" name="residential_address_country05" style={{ width: "100%" }}
                                                        data-select2-type="simple"
                                                        data-select2-paging="false"
                                                        data-select2-placeholder="Select Country"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        objValue={{ value: this.state.countryID05, name: this.state.countryName05 }}
                                                        getValue={(event) => this.handleUpdateCountry(5, event)}
                                                        listHasPlaceholder={false}
                                                        placeholder={"Select Country"}
                                                        data={countries != null ? countries : []} />
                                                    <label htmlFor="residential_address_country05" className="small">{getResource('Label_Country')} *</label>
                                                    {(this.state.validations.countryID05 != null && !this.state.validations.countryID05) &&
                                                        <label id="residential_address_country-error05" class="error" htmlFor="residential_address_country05">Field Required.</label>
                                                    }
                                                </div> {/* .col-md-6 */}
                                            </div> {/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.ResidentialAddressStateId05)}>
                                                    {
                                                        this.state.statesByCountryID05.length > 0 ?
                                                            <DynamicSelect
                                                                className="form-control use-select2 select2-selection__placeholder" id="residential_address_state05" name="residential_address_state05" style={{ width: "100%" }}
                                                                data-select2-type="simple"
                                                                data-select2-paging="false"
                                                                data-select2-placeholder="Select State"
                                                                data-rule-required="true"
                                                                data-msg-required="Field Required"
                                                                objValue={{ value: this.state.ResidentialAddressStateId05, name: this.state.ResidentialAddressStateName05 }}
                                                                getValue={this.handleUpdateResidentialAddressState.bind(this, 5)}
                                                                listHasPlaceholder={false}
                                                                placeholder={"Select State"}
                                                                data={this.state.statesByCountryID05} />
                                                            :
                                                            <input type="text" className="form-control"
                                                                id="residential_address_postalcode05"
                                                                name="residential_address_postalcode05" maxLength="50"
                                                                data-rule-required="true"
                                                                data-msg-required="Field Required."
                                                                value={this.state.OtherState05}
                                                                onChange={this.handleUpdateState.bind(this, 5)} />
                                                    }

                                                    <label htmlFor="residential_address_state05" className="small">{getResource('Label_State')} *</label>
                                                    {(this.state.validations.ResidentialAddressStateId05 != null && !this.state.validations.ResidentialAddressStateId05) &&
                                                        <label id="residential_address_state_name-error05" class="error" htmlFor="residential_address_state_name05">Field Required.</label>
                                                    }
                                                </div>
                                                <div className={"col-sm-6 col-xs-12 form-group" + formatValidInput(this.state.validations.ResidentialAddressPostalCode05)}>
                                                    <input type="text" className="form-control" id="residential_address_postalcode05" name="residential_address_postalcode05" maxLength="50"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required."
                                                        value={this.state.ResidentialAddressPostalCode05}
                                                        onChange={this.handleUpdateResidentialAddressPostalCode.bind(this, 5)} />
                                                    <label htmlFor="residential_address_postalcode05" className="small">{getResource('Label_Postal')}*</label>
                                                    {(this.state.validations.ResidentialAddressPostalCode05 != null && !this.state.validations.ResidentialAddressPostalCode05) &&
                                                        <label id="residential_address_postalcode-error05" class="error" htmlFor="residential_address_postalcode05">Field Required.</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <h5><b>Owner Info</b></h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12 form-group"}>
                                                    <PhoneInput
                                                        autoFormat
                                                        enableSearch
                                                        id="phone-number-05"
                                                        name="phone-number-05"
                                                        placeholder="Enter phone number"
                                                        country={defaultMobileCountry}
                                                        copyNumbersOnly={false}
                                                        countryCodeEditable={false}
                                                        value={this.state.ResidentialPhoneNumber05}
                                                        onChange={this.handleUpdateResidentialPhoneNumber.bind(this, 5)}
                                                    />
                                                    <label htmlFor="residential_phone_number05" className="small">Owner Number </label>
                                                </div>
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.EmailAddress05)}>
                                                    <input type="email" className="form-control" id="email_address05" name="email_address05" maxLength="150"
                                                        data-rule-required="true"
                                                        data-msg-required="Field Required"
                                                        data-rule-email="true"
                                                        data-msg-email="Email Required"
                                                        value={this.state.EmailAddress05}
                                                        onChange={this.handleUpdateEmailAddress.bind(this, 5)} />
                                                    <label htmlFor="email_address05" className="small">{getResource('Label_Email')}*</label>
                                                    {(this.state.validations.EmailAddress05 != null && !this.state.validations.EmailAddress05) &&
                                                        <label id="email_address-error05" class="error" htmlFor="email_address05">Field Required.</label>
                                                    }
                                                </div>
                                                {/* <div className="col-sm-6 col-xs-12 form-group">
                                                    <input type="text" className="form-control" id="SSN05" name="SSN05" maxLength="100"
                                                        value={this.state.SSN05}
                                                        onChange={this.handleUpdateSSN.bind(this, 5)} />
                                                    <label htmlFor="SSN05" className="small">{getResource('Label_SSN')}</label>
                                                </div> */}
                                            </div> {/* .row */}
                                            <div className="row">
                                                <div className={"col-sm-6 col-xs-12" + formatValidInput(this.state.validations.PercentageOwnership05)}>
                                                    <NumberInput
                                                        id="PercentageOwnership05"
                                                        type="Integer"
                                                        className="form-control"
                                                        value={this.state.PercentageOwnership05}
                                                        min={25}
                                                        max={100}
                                                        onChange={this.handlePercentageOwnership.bind(this, 5)}
                                                    />
                                                    <label htmlFor="PercentageOwnership05" className="small">Percentage Ownership*</label>
                                                    {(this.state.validations.PercentageOwnership05 != null && !this.state.validations.PercentageOwnership05) &&
                                                        <label id="PercentageOwnership-error05" class="error" htmlFor="PercentageOwnership05">Field Required.</label>
                                                    }
                                                </div>
                                            </div>


                                            {/* <ContactAdditionalInformation
                                                key={'primaryContact05'}
                                                notFirstLoad={this.props.notFirstLoad}
                                                totalList={this.state.allAdditionalPropertiesDefs5}
                                                customerAdditionalProperties={this.state.contactAdditionalProperties05}
                                                values={this.props.valuesOfAdditionalPropertiesDefs5}
                                                validations={this.props.validationsOfAdditionalPropertiesDefs5}
                                                onUpdateValues={this.updateValueListAdditionalProppertiesDefs}
                                                step={3}
                                                principal={3}
                                            /> */}

                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        }
                        {/* END TRADER 05 */}
                    </div>
                </div>
                <AlertBox open={this.state.showDeleteAlert} onClose={this.closeDeleteAlert.bind(this)} title="Are you sure you want to delete this file?" message="If you delete this file, you will need to upload all the corresponding files. This action can not be undone." type="Yes/No" yesClick={this.yesDeleteAlert.bind(this)} noClick={this.closeDeleteAlert.bind(this)} />
                <DialogOwnerInvitation open={this.state.openSendEmail} close={this.CloseSendOwnerEmail} contactType={"primary"} />
            </div>
        );
    } else {
        return (
            <div>
                <LoadingBox loading={true}></LoadingBox>
            </div>
        );
    }
    }
}